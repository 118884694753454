import { useBrandStore } from "../stores/brand";

/**
 * Formats a message by replacing placeholders with backend-compatible values.
 *
 * @param {string} msg - The message to be formatted.
 * @return {string} The formatted message.
 */
export const formatFEtoBESms = (msg) => {
  if (!msg) return "";

  return msg
    .replaceAll("{BRAND_NAME}", "aaaa")
    .replaceAll("{INCENTIVE}", "bbbb")
    .replaceAll("{EXPIRY_DATE}", "cccc")
    .replaceAll("{REDEMPTION_CODE}", "dddd")
    .replaceAll("{MEMBER_NAME}", "{name}")
    .replaceAll("{TIER_LEVEL_NAME}", "{tier_level}");
};

/**
 * Formats a raw SMS message by replacing backend-compatible values with their corresponding frontend placeholders.
 *
 * @param {string} msg - The raw SMS message to be formatted.
 * @return {string} The formatted SMS message.
 */
export const formatBEtoFESms = (msg) => {
  if (!msg) return "";

  return msg
    .replaceAll("aaaa", "{BRAND_NAME}")
    .replaceAll("bbbb", "{INCENTIVE}")
    .replaceAll("cccc", "{EXPIRY_DATE}")
    .replaceAll("dddd", "{REDEMPTION_CODE}")
    .replaceAll("{name}", "{MEMBER_NAME}")
    .replaceAll("{tier_level}", "{TIER_LEVEL_NAME}");
};

/**
 * Formats the display SMS message.
 *
 * @param {string} msg - The message to be formatted.
 * @param {string} incentive - The incentive to be included in the message.
 * @param {string|null} [memberName=null] - The member name to be included in the message.
 * @return {string} The formatted display SMS message.
 */
export const formatBeToDisplaySms = (
  msg,
  incentive,
  memberName = null,
  tierName = null
) => {
  if (!msg) return "";

  const store = useBrandStore();

  let prefix = store.brand.countryCode === "MY" ? "RM0" : "[SIMLO.ID]";

  const newMessage = msg
    .replaceAll("aaaa", store.brand.name)
    .replaceAll("bbbb", incentive)
    .replaceAll("dddd", "xxxx")
    .replaceAll("cccc", "dd/mm/yyyy")
    .replaceAll("{name}", memberName ? memberName : "{name}")
    .replaceAll("{tier_level}", tierName);

  return `${prefix} ${newMessage}`;
};

export const formatFEToDisplay = (
  msg,
  incentive,
  memberName = null,
  tierName = null
) => {
  if (!msg) return "";

  const store = useBrandStore();

  const newMessage = msg
    .replaceAll("{BRAND_NAME}", store.brand.name)
    .replaceAll("{INCENTIVE}", incentive)
    .replaceAll("{REDEMPTION_CODE}", "xxxx")
    .replaceAll("{EXPIRY_DATE}", "dd/mm/yyyy")
    .replaceAll("{MEMBER_NAME}", memberName ? memberName : "{name}")
    .replaceAll("{TIER_LEVEL_NAME}", tierName);

  return newMessage;
};

/**
 * Checks if the given input string is a valid SMS message based on the provided country code.
 *
 * @param {string} countryCode - The country code to be used for validation.
 * @param {string} input - The input string to be validated.
 * @return {boolean} True if the input is a valid SMS message, false otherwise.
 */
export const validCountrySmsRestriction = (countryCode, input) => {
  switch (countryCode) {
    case "MY":
      return validSmsMalaysia(input);
    default:
      return true;
  }
};

/**
 * Checks if the given input string is a valid SMS message in Malaysia.
 *
 * @param {string} input - The input string to be validated.
 * @return {boolean} True if the input is a valid SMS message, false otherwise.
 */
export const validSmsMalaysia = (input) => {
  if (!input) return true; // Empty input

  // Regular expression for detecting URLs
  const urlPattern =
    /(?:https?:\/\/|www\.|\.com|\.co|\.my|\.ly|\.com\.my|\.com\/|\.com\/my)/i;

  // Regular expression for detecting sequences of 8 or more numbers
  const numberPattern = /\d{8,}/;

  // Regular expression for detecting texts with no space behind dots
  // E.g. "Hello.World >> Pattern Match!"
  const noSpaceBehindDots = /\.\S/;

  if (
    urlPattern.test(input) ||
    numberPattern.test(input) ||
    noSpaceBehindDots.test(input)
  ) {
    return false;
  }

  return true;
};
