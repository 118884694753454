import {
  createClient,
  cacheExchange,
  fetchExchange,
  mapExchange,
  subscriptionExchange,
} from "@urql/vue";
import { createConsumer } from "@rails/actioncable";
import SubscriptionExchange from "graphql-ruby-client/subscriptions/SubscriptionExchange";

const actionCable = createConsumer(
  `${process.env.VUE_APP_WEBSOCKET_ENDPOINT}/cable?app=dashboard`
);

const forwardToActionCable = SubscriptionExchange.create({
  consumer: actionCable,
  channelName: "SlDashboardChannel",
});

export const client = createClient({
  url: process.env.VUE_APP_BACKEND_URI,
  exchanges: [
    mapExchange({
      onError(error) {
        if (`${error}`.match(/Deactivated/)) {
          localStorage.setItem("brandDeactivated", "true");
        } else {
          localStorage.setItem("brandDeactivated", "false");
        }
      },
    }),
    cacheExchange,
    fetchExchange,
    subscriptionExchange({
      forwardSubscription: forwardToActionCable,
    }),
  ],
  requestPolicy: "network-only",
  fetchOptions: () => {
    return {
      headers: {
        app: "dashboard",
      },
      credentials: "include",
    };
  },
});

export const nlClient = createClient({
  url: process.env.VUE_APP_PUBLIC_BACKEND_URI,
  exchanges: [cacheExchange, fetchExchange],
  requestPolicy: "network-only",
  fetchOptions: () => {
    return {
      headers: {
        app: "dashboard",
      },
      credentials: "include",
    };
  },
});
