<template>
  <InvitationContainer />
  <ConnectivityBanner />
  <InviteUserContainer />
  <slot></slot>
</template>
<script setup>
import ConnectivityBanner from "@/reusables/ConnectivityBanner.vue";
import InvitationContainer from "@/reusables/InvitationContainer.vue";
import { useQuery } from "@urql/vue";
import {
  DASHBOARD_TOOLTIPS,
  SL_PLAN_NOTIFICATION_CONFIG,
  DASHBOARD_TRAINING_PROGRAM,
  USER_COMPLETED_TRAINING,
} from "@/constants/graphql";
import { useToolTipData } from "@/stores/tooltipData.js";
import { useNotificationConfig } from "@/stores/notificationConfig.js";
import { useTrainingData } from "@/stores/trainingProgram.js";
import { watch } from "vue";
import InviteUserContainer from "@/reusables/InviteUserContainer.vue";

const tooltip = useToolTipData();
const tooltipData = useQuery({
  query: DASHBOARD_TOOLTIPS,
});

const notificationConfig = useNotificationConfig();
const dataConfig = useQuery({
  query: SL_PLAN_NOTIFICATION_CONFIG,
});

tooltip.$patch({ query: tooltipData });

watch(tooltipData.fetching, (fetchStatus) => {
  if (!fetchStatus && tooltipData.data.value) {
    tooltip.$patch({
      tooltipData: tooltipData.data.value.dashboardTooltips,
    });
  }
});

notificationConfig.$patch({ query: dataConfig });

watch(dataConfig.fetching, (fetchStatus) => {
  if (!fetchStatus && dataConfig.data.value) {
    notificationConfig.$patch({
      notificationConfig: dataConfig.data.value.slPlanNotificationConfigs,
    });
  }
});

const trainingProgram = useTrainingData();
const trainingData = useQuery({
  query: DASHBOARD_TRAINING_PROGRAM,
});

trainingProgram.$patch({ trainingQuery: trainingData });

watch(trainingData.fetching, (fetchStatus) => {
  if (!fetchStatus && trainingData.data.value) {
    trainingProgram.$patch({
      trainingProgramData:
        trainingData.data.value.dashboardTrainingProgram
          .dashboardTutorialPopups,
    });
  }
});

const completedTrainingPopupQuery = useQuery({
  query: USER_COMPLETED_TRAINING,
});

trainingProgram.$patch({ completedTrainingQuery: completedTrainingPopupQuery });

watch(completedTrainingPopupQuery.fetching, (fetchStatus) => {
  if (!fetchStatus && completedTrainingPopupQuery.data.value) {
    trainingProgram.$patch({
      completedTrainingPopupsIds:
        completedTrainingPopupQuery.data.value.userCompletedTrainingPopupIds,
    });
  }
});
</script>
