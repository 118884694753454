<template>
  <SlModal id="invitation-modal">
    <InvitationModal
      :selected-brand="selectedBrand"
      @update-active-status="updateActiveStatus"
      @close-modal="closeModal"
    />
  </SlModal>
</template>
<script setup>
import SlModal from "@/components/uielements/modal/SlModal.vue";
import InvitationModal from "@/pages/SignIn/InvitationModal.vue";
import { useMutation } from "@urql/vue";
import { ACCEPT_BRAND_INVITE } from "@/constants/graphql";
import { Modal } from "bootstrap";
import { useBrandStore } from "@/stores/brand.js";
import { ref, onMounted } from "vue";

// INVITATION MODAL
const store = useBrandStore();
const invitationModal = ref(null);
onMounted(() => {
  invitationModal.value = new Modal("#invitation-modal");
});

const selectedBrand = ref({});

const { executeMutation: acceptBrandInviteMutation } =
  useMutation(ACCEPT_BRAND_INVITE);

const handleInvite = (brand) => {
  selectedBrand.value = brand;
  invitationModal.value.show();
};

store.$patch({
  invitationTrigger: handleInvite,
});

function closeModal() {
  invitationModal.value.toggle();
}

function updateActiveStatus(id) {
  acceptBrandInviteMutation({
    brandId: id,
  })
    .then((result) => {
      if (result.data.acceptBrandInvite.errors.length > 0) {
        alert("An error occured when accepting the invitation.");
      } else {
        let updatedBrandData;
        updatedBrandData = store.userBrandData.map((brand) => {
          if (brand.id === id) {
            brand.status = "Active";
          }
          return brand;
        });
        closeModal();
      }
    })
    .catch((error) => {
      alert("An error occured when accepting the invitation.");
    });
}
// INVITATION MODAL
</script>
