/* eslint-disable */
import CryptoJS from "crypto-js";
import axios from "axios";
import cookie from "./utilities/cookie.js";
import { usePermissionsStore } from "@/stores/permissions.js";
import LogRocket from "logrocket";

/**
 * Encrypts the given data using the encryption key.
 * @param {any} data - the data to encrypt
 * @returns {string} the encrypted data
 */
export const encrypt = (data) => {
  return CryptoJS.AES.encrypt(
    JSON.stringify(data),
    process.env.VUE_APP_ENCRYPTION_KEY
  ).toString();
};

/**
 * Decrypts the given cipher text using the encryption key.
 * @param {string} cipherText - the cipher text to decrypt
 * @returns {string} the decrypted text
 */
export const decrypt = (cipherText) => {
  var bytes = CryptoJS.AES.decrypt(
    cipherText,
    process.env.VUE_APP_ENCRYPTION_KEY
  );
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

export const hashString = (string) => {
  return CryptoJS.SHA256(string).toString(CryptoJS.enc.Hex);
};

/**
 * Sets the value of a key in the local storage.
 * @param {string} key - the key to set the value of
 * @param {any} value - the value to set the key to
 * @returns None
 */
export const setLocalData = (key, value) => {
  let secureKey = hashString(key);
  localStorage.setItem(secureKey, encrypt(JSON.stringify(data)));
};

/**
 * Gets the data stored in local storage.
 * @param {string} [key=null] - the key to get the data for.
 * @returns The data stored in local storage.
 */
export const getLocalData = (key = null) => {
  if (typeof window === "undefined") {
    return null;
  }

  let secureKey = hashString(key);
  let data = localStorage.getItem(secureKey);

  if (!data) {
    return null;
  } else {
    data = decrypt(data);
  }
  return data;
};

/**
 * Sets the value of a key in the local storage.
 * @param {string} key - the key to set the value of
 * @param {any} value - the value to set the key to
 * @returns None
 */
export const setSessionData = (key, value) => {
  let secureKey = hashString(key);
  sessionStorage.setItem(secureKey, encrypt(value));
};

/**
 * Gets the data stored in local storage.
 * @param {string} [key=null] - the key to get the data for.
 * @returns The data stored in local storage.
 */
export const getSessionData = (key = null) => {
  if (typeof window === "undefined") {
    return null;
  }

  let secureKey = hashString(key);
  let data = sessionStorage.getItem(secureKey);

  if (!data) {
    return null;
  } else {
    data = decrypt(data);
  }
  return data;
};

export async function setCurrentUser() {
  const store = usePermissionsStore();
  let userUrl = `${process.env.VUE_APP_ENDPOINT}current_user`;
  var ret = true;
  try {
    let result = await axios({
      method: "GET",
      url: userUrl,
      withCredentials: true,
    });
    if (result.data.active_permissions) {
      store.setData(result.data.active_permissions);
    }
    if (cookie.getSecureCookie("mulah_nm") === false) {
      cookie.setSecureCookie("mulah_nm", result.data.name, false, true);
    }
    if (cookie.getSecureCookie("mulah_em") === false) {
      cookie.setSecureCookie("mulah_em", result.data.email, false, true);
    }
    if (cookie.getSecureCookie("mulah_id") === false) {
      cookie.setSecureCookie("mulah_id", result.data.id, false, true);
    }

    LogRocket.identify(result.data.id, {
      name: result.data.name,
      email: result.data.email,
    });

    return true;
  } catch (error) {
    if (error.response.status === 500) {
      alert("An unexpected error has occured");
    }
    return false;
  }
}
