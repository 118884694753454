import { defineStore } from "pinia";
import { ref, watch, computed } from "vue";
import { currencySymbol } from "@/constants/country_currency.js";

export const useBrandStore = defineStore("brand", () => {
  const brand = ref(null);
  const currSym = ref("RM");
  const query = ref(null);
  const totalUnsentMessages = ref(0);
  const messageInQueue = ref(0);

  const userBrandData = ref([]);
  const invitationTrigger = ref(null);

  const slug = computed(() => brand.value?.slug || "");

  watch(brand, (newBrand) => {
    if (newBrand) {
      currSym.value = currencySymbol(newBrand.countryCode);
      totalUnsentMessages.value = newBrand.totalUnsentMessages || 0;
      messageInQueue.value = newBrand.messageInQueue || 0;
    }
  });

  const outlets = ref([]);
  const getStores = computed(() => outlets.value);

  const totalMessagesOnHold = computed(() => {
    return totalUnsentMessages.value + messageInQueue.value;
  });

  async function getBrand() {
    if (brand.value == null) {
      console.log("Fetching brand");
      const res = await query.value.executeQuery();
      if (res.data && res.data.value.slBrand) {
        brand.value = res.data.value.slBrand;
      }
    }
  }

  return {
    brand,
    slug,
    currSym,
    query,
    outlets,
    getStores,
    getBrand,
    totalUnsentMessages,
    messageInQueue,
    totalMessagesOnHold,
    userBrandData,
    invitationTrigger,
  };
});

export const useBrandSetupStore = defineStore("brand-setup", {
  state: () => {
    return {
      brandCountry: "",
      brandTimeZone: "",
      brandIndustry: "",
      brandCurrency: "",
      brandName: "",
      brandStoreLocation: "",
      brandStoreTimezone: "",
      brandStores: "",
      brandEarningSelection: "",
      brandSpending: "",
      brandPoints: "",
      brandRewards: "",
      newBrandId: "",
      brandOutletCredentials: [],
      page: "location",
    };
  },
});

export const useBrandStoreCredentials = defineStore("brand-store-credentials", {
  state: () => {
    return {
      outletCredentials: [],
    };
  },
});
