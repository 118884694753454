<!-- eslint-disable prettier/prettier -->
<template>
  <div class="modal-body">
    <div class="d-flex justify-content-end">
      <button
        type="button"
        class="btn-close position-absolute top-0 end-0"
        style="margin-right: 6px; margin-top: 8px"
        aria-label="Close"
        @click="handleCloseModal"
      ></button>
    </div>
    <div class="deal-img d-flex flex-row justify-content-center">
      <img src="../../assets/deal.svg" alt="" style="width: 42.5%" />
    </div>
    <div class="d-flex flex-column">
      <h3>
        <span class="text-red">{{ props.selectedBrand.inviterName }}</span>
        from
        <span class="text-red">{{ props.selectedBrand.brandName }}</span>
        has invited you to manage their brand.
      </h3>
      <p style="font-size: 14px" class="my-2">
        By accepting this invitation, you will gain access to their powerful
        suite of tools that will enhance your ability to manage and optimize
        their brand's online presence!
      </p>
    </div>
    <button
      class="btn btn-primary w-100 mt-2 border-0"
      style="background-color: #ff0000; letter-spacing: 0.05rem"
      @click="handleAcceptInvite"
    >
      Accept Invitation
    </button>
  </div>
</template>
<script setup>
import { defineProps, defineEmits } from "vue";

const props = defineProps({
  selectedBrand: {
    type: Object,
    required: true,
  },
});
const emit = defineEmits(["close-modal", "update-active-status"]);

function handleCloseModal() {
  emit("close-modal");
}

function handleAcceptInvite() {
  emit("update-active-status", props.selectedBrand.id);
  handleCloseModal();
}
</script>
