import { useBrandStore } from "@/stores/brand.js";

// TODO: Remove this
export const currencySymbol = (countryCode) => {
  if (countryCode === "MY") {
    return "RM";
  } else if (countryCode === "ID") {
    return "Rp";
  } else {
    return "USD";
  }
};

export function currencyConvertWCountry(amount, showCurrSym = true) {
  const store = useBrandStore();
  const currSym = store.currSym;

  if (currSym === "Rp") {
    return currSym + parseFloat(amount).toLocaleString("en-US");
  }

  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  if (showCurrSym) {
    return currSym + amount.toLocaleString("en-US", options);
  } else {
    return amount.toLocaleString("en-US", options);
  }
}

// TODO: Remove this
export function currency(cc) {
  return currencySymbol[cc] || cc;
}
