<template>
  <div v-if="!showIconWifi && !network.isOnline" class="wrapper">
    <div class="card connectivity-card my-3 mx-2 shadow-lg">
      <div class="row card-body">
        <div class="col-auto d-flex align-items-center" style="max-width: 70px">
          <IconNoInternet />
        </div>
        <div class="col text-start">
          <div class="row lh-sm fw-600 pe-3">
            You are not connected to the internet
          </div>
          <div class="row lh-sm">Please check your internet connection</div>
        </div>
        <div class="col-auto d-flex align-items-center">
          <ButtonClose
            class="btn-close-white"
            @click="handleClick('disconnected')"
          />
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="!showIconWifi && isLowBandwidth" class="wrapper">
    <div class="card connectivity-card my-3 mx-2 shadow-lg">
      <div class="row card-body">
        <div class="col-auto d-flex align-items-center" style="max-width: 70px">
          <UnstableConnection />
        </div>
        <div class="col text-start">
          <div class="row lh-sm fw-600 pe-3">
            Your internet connection is unstable
          </div>
          <div class="row lh-sm">Please check your internet connection</div>
        </div>
        <div class="col-auto d-flex align-items-center">
          <ButtonClose
            class="btn-close-white"
            @click="handleClick('lowBandwidth')"
          />
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="!showIconWifi && showBackOnlineBanner" class="wrapper">
    <div class="card connectivity-card my-3 mx-2 shadow-lg">
      <div class="row card-body">
        <div class="col-auto d-flex align-items-center" style="max-width: 70px">
          <i class="bi bi-check-circle"></i>
        </div>
        <div class="col text-start ps-0 pe-2 fw-600">
          You are connected to the internet again!
        </div>
        <div class="col-auto d-flex align-items-center">
          <ButtonClose
            class="btn-close-white"
            @click="handleClick('connected')"
          />
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="showIconWifi" class="wrapper-wifi">
    <div class="card connectivity-card my-3 mx-2 shadow-lg">
      <div class="row card-body">
        <div class="col-auto d-flex align-items-center" style="max-width: 70px">
          <IconNoInternet v-if="!network.isOnline" />
          <UnstableConnection v-else-if="isLowBandwidth" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { watch, ref, computed } from "vue";
import { useNetwork } from "@vueuse/core";
import IconNoInternet from "@/assets/icons/ICO95.vue";
import ButtonClose from "@/components/uielements/button-close/ButtonClose.vue";
import UnstableConnection from "@/assets/icons/UnstableConnection.vue";

const showBackOnlineBanner = ref(false);
const network = ref(useNetwork());
const showIconWifi = ref(false);

watch(
  () => [network.value.isOnline, network.value.downlink],
  ([isOnline]) => {
    showIconWifi.value = false;
    if (isOnline || !isLowBandwidth.value) {
      showBackOnlineBanner.value = true;
      setTimeout(() => {
        showBackOnlineBanner.value = false;
      }, 3000);
    }
  }
);

const isLowBandwidth = computed(() => {
  if (network.value.isSupported) {
    return network.value.downlink < 5;
  } else {
    return false;
  }
});

function handleClick(value) {
  if (value === "disconnected" || value === "lowBandwidth") {
    showIconWifi.value = true;
  } else if (value === "connected") {
    showBackOnlineBanner.value = false;
  }
}
</script>
<style scoped>
.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 2001;
  color: #1c1c1c;
  display: flex;
  justify-content: center;
  pointer-events: none;
}

.wrapper > * {
  pointer-events: auto;
}

.wrapper-wifi {
  position: fixed;
  top: -13px;
  width: 100%;
  z-index: 2001;
  color: #1c1c1c;
  display: flex;
  pointer-events: none;
  justify-content: center;
}

.connectivity-card {
  background-color: #4c4c4c;
  color: white;
}
</style>
