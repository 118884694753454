<template>
  <svg
    width="25"
    height="26"
    viewBox="0 0 25 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.85418 17.1667C9.7573 16.2635 10.8542 15.7521 11.9792 15.6312M5.72918 13.5208C9.03439 10.2156 13.1479 9.53123 16.6667 11.4708M2.08334 9.35415C8.66251 3.79894 16.3375 3.79894 22.9167 9.35415M21.875 14.5625L15.625 20.8125M21.875 20.8125L15.625 14.5625"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
