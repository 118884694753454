import { defineStore } from "pinia";
import { ref } from "vue";

export const useTrainingData = defineStore("trainingProgram", () => {
  const trainingProgramData = ref([]);
  const trainingQuery = ref(null);
  const completedTrainingPopupsIds = ref([]);
  const completedTrainingQuery = ref(null);

  return {
    trainingQuery,
    trainingProgramData,
    completedTrainingPopupsIds,
    completedTrainingQuery,
  };
});
