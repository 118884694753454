<template>
  <div class="modal-body">
    <div class="d-flex justify-content-end">
      <button
        type="button"
        class="btn-close position-absolute top-0 end-0"
        style="margin-right: 6px; margin-top: 8px"
        aria-label="Close"
        data-bs-dismiss="modal"
      ></button>
    </div>
    <div class="deal-img d-flex flex-row justify-content-center"></div>
    <div class="text-container">
      <h3 class="modal-title">Invite User</h3>
      <p class="modal-description my-2" style="color: #697586">
        Send an invitation to your team members or friends!
      </p>
    </div>
    <div
      class="card-container d-flex justify-content-center align-items-center"
    >
      <div
        class="card-hover card"
        style="border-width: 0"
        @click="moveNextPage('/account/permissions?addEmployee=true')"
        data-bs-dismiss="modal"
      >
        <div class="card inner-card">
          <div
            class="card-body d-flex flex-column justify-content-start align-items-center pt-3"
          >
            <img
              class="icon"
              src="@/assets/svg/teamMember.svg"
              alt="team member svg"
            />
            <p style="margin-bottom: 0">Team Member</p>
            <p class="mt-1 fw-500 sub-text">
              Get someone from your team on board!
            </p>
          </div>
        </div>
      </div>
      <div
        class="card-hover card"
        style="border-width: 0"
        @click="moveNextPage('/referral')"
        data-bs-dismiss="modal"
      >
        <div class="card inner-card">
          <div
            class="card-body d-flex flex-column justify-content-start align-items-center pt-3"
          >
            <img class="icon" src="@/assets/svg/friend.svg" alt="friend svg" />
            <p style="margin-bottom: 0px">Refer a Friend</p>
            <p class="mt-1 fw-500 sub-text">
              Know a friend who'd love <br />a free Loyalty Program? Hook them
              up!
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import router from "@/router";

const moveNextPage = (path) => {
  router.push(path);
};
</script>

<style scoped>
.modal-body {
  width: 100%;
  height: 100%;
  padding: 0;
}

.close-btn {
  cursor: pointer;
  font-size: 1.25rem;
}

.close-btn:hover {
  color: #ea3323 !important;
}

.text-container {
  text-align: center;
  margin-bottom: 1.25rem;
}

.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
}

.card-hover {
  width: 100%;
  height: 100%;
  padding: 0.5rem;
}

.card-body {
  width: 100%;
  height: 100%;
  justify-content: center;
}

.inner-card {
  width: 100%;
  height: 180px;
  box-shadow: 0 3px 3px -5px rgba(0, 0, 0, 1);
  justify-content: center;
}

.card-hover:hover {
  cursor: pointer;
}

.card-hover .inner-card {
  border-radius: 0.5rem;
  transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.card-hover .inner-card:hover {
  box-shadow: 0 0 10px #ff0000;
}

.card-hover .inner-card:hover .icon {
  filter: brightness(0) invert(25%) sepia(100%) saturate(10000%);
}

.card-hover .inner-card:hover p {
  color: #ff0000;
}

.sub-text {
  font-size: 12px;
  color: #697586;
  text-align: center;
}
</style>
