import { encrypt, decrypt } from "../session.js";

const cookie = {
  getCookie(name) {
    let value = `; ${document.cookie}`;
    let parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  },
  setCookie(key, value, maxAge) {
    if (maxAge) {
      document.cookie = `${key}=${value};max-age=31536000`;
    } else {
      document.cookie = `${key}=${value}`;
    }
  },
  setSecureCookie(
    key,
    value,
    maxAge,
    sameSite,
    encrypted = true,
    maxAgeVal = 31536000
  ) {
    let cookieVal = encrypted === true ? encrypt(value) : value;
    let sameSiteValue = "lax";
    if (sameSite == true) {
      sameSiteValue = "strict";
    }
    if (maxAge) {
      document.cookie = `${key}=${cookieVal};max-age=${maxAgeVal};secure;samesite=${sameSiteValue}`;
    } else {
      document.cookie = `${key}=${cookieVal};secure;samesite=${sameSiteValue}`;
    }
  },
  getSecureCookie(name, encrypted = true) {
    let value = `; ${document.cookie}`;
    let parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      let value = parts.pop().split(";").shift();
      let retVal = encrypted === true ? decrypt(value) : value;
      return retVal;
    } else {
      return false;
    }
  },
  expireCookie(name) {
    document.cookie = `${name}=;max-age=0;secure;samesite=true;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  },
};

export default cookie;
