/* eslint-disable */
import { createRouter, createWebHashHistory } from "vue-router";
import { setCurrentUser } from "@/session";
import cookie from "@/utilities/cookie.js";
import { useBrandStore } from "@/stores/brand.js";

// General
const DeactivatedWarning = () =>
  import("@/pages/General/DeactivatedWarning.vue");

// Dashboard
const Dashboard = () => import("@/pages/Dashboard/Index.vue");
const Debug = () => import("@/pages/Dashboard/Debug.vue");

// Login screens
const Login = () => import("@/pages/SignIn/Index.vue");
const LinkBrand = () => import("@/pages/SignIn/LinkBrand.vue");
const AuthEmail = () => import("@/pages/SignIn/AuthEmail.vue");
const ResetPassword = () => import("@/pages/SignIn/ResetPassword.vue");
const ResetPasswordExpired = () =>
  import("@/pages/CreateAccount/ResetPasswordExpired.vue");
const VerificationCode = () => import("@/pages/SignIn/VerificationCode.vue");
const VerifyEmail = () => import("@/pages/SignIn/VerifyEmail.vue");
const InvalidConfirmation = () =>
  import("@/pages/SignIn/InvalidConfirmation.vue");
const ChooseBrand = () => import("@/pages/SignIn/ChooseBrand.vue");
const CreateAccount = () => import("@/pages/CreateAccount/Index.vue");

// Sidebar > Marketing
const MarketingButton = () => import("@/pages/Marketing/Index.vue");
const AcquisitionPage = () =>
  import("@/pages/Marketing/AcquisitionPage/Index.vue");
const MessageBroadcast = () =>
  import("@/pages/Marketing/BroadcastMessage/Index.vue");
const MessageBroadcastReport = () =>
  import(
    "@/pages/Analytics/MarketingAnalytics/MessageBroadcast/Report/Report.vue"
  );
const AutomationPage = () =>
  import("@/pages/Marketing/AutomatedMessage/Index.vue");
const AutomationReport = () =>
  import("@/pages/Analytics/MarketingAnalytics/MessageAutomation/Report.vue");
const SocialMedia = () => import("@/pages/Marketing/SocialMedia/Index.vue");
const LandingPage = () => import("@/pages/Marketing/LandingPage/Index.vue");
const Giveaway = () => import("@/pages/Marketing/Giveaway/Index.vue");
const WhatsappTemplate = () =>
  import("@/pages/Marketing/WhatsappTemplate/Index.vue");
const MessagesonHold = () =>
  import("@/pages/Marketing/MessagesonHold/Index.vue");
// Sidebar > Members
const MembersPage = () => import("@/pages/Members/Index.vue");

// Sidebar > Analytics
const MonthlyReports = () =>
  import("@/pages/Analytics/MonthlyReports/Index.vue");
const MarketingAnalytics = () =>
  import("@/pages/Analytics/MarketingAnalytics/Index.vue");
const LandingPageAnalytics = () =>
  import("@/pages/Analytics/LandingPageAnalytics/Index.vue");
const MemberAnalytics = () => import("@/pages/Members/Analytics/Index.vue");
const AutoRecharge = () =>
  import("@/pages/Billing/RechargeSMSCredit/AutoRecharge/Index.vue");

// Sidebar > Loyalty Program
const SpendingPointConversion = () =>
  import("@/pages/LoyaltyProgram/EarningPoints/Index.vue");
const RewardPage = () =>
  import("@/pages/LoyaltyProgram/RedeemingPoints/Index.vue");
const VipCircles = () => import("@/pages/LoyaltyProgram/VipCircles/Index.vue");
const MemberPortal = () =>
  import("@/pages/LoyaltyProgram/MemberPortal/MemberPortal.vue");

// Sidebar > Billing
const Subscriptions = () => import("@/pages/Billing/Subscriptions/Index.vue");
const CreditHistory = () =>
  import("@/pages/Billing/RechargeSMSCredit/CreditHistory/Index.vue");

// Menu > Account > Settings
const UserPermissionPage = () =>
  import("@/pages/AccountManagement/Settings/Permissions/Index.vue");
const AccountUserInfoPage = () =>
  import("@/pages/AccountManagement/Settings/UserInfo/Index.vue");
const LanguagePage = () =>
  import("@/pages/AccountManagement/Settings/Languages/Index.vue");

// Menu > Account > Store
const StoreSecurity = () =>
  import("@/pages/AccountManagement/Store/StoreSecurity/Index.vue");
const SecurityLogs = () =>
  import("@/pages/AccountManagement/Store/SecurityLogs/Index.vue");
const ResetStore = () =>
  import("@/pages/AccountManagement/Store/AddStore/Index.vue");

// Menu > Account > Integrations
const Marketing = () =>
  import("@/pages/AccountManagement/Integrations/Marketing/Index.vue");
const LoyaltyProgram = () =>
  import("@/pages/AccountManagement/Integrations/LoyaltyProgram/Index.vue");
const MarketingV2 = () => import ("@/pages/AccountManagement/Integrations/MarketingV2/Index.vue")

// Menu > Account > Other
const UserOnboarding = () =>
  import("@/pages/AccountManagement/UserOnboarding/Index.vue");
const GetStarted = () =>
  import("@/pages/AccountManagement/GetStarted/Index.vue");
const MarketingSetting = () =>
  import("@/pages/AccountManagement/MarketingSetting/Index.vue");
const Logout = () => import("@/pages/AccountManagement/Logout/Index.vue");
const Referral = () => import("@/pages/UserReferrals/Index.vue");

// Miscellaneous
const ComingSoon = () => import("@/pages/reusables/ComingSoon/Index.vue");
const DemoIndex = () =>
  import("@/pages/Billing/RechargeSMSCredit/DemoIndex/Index.vue");
const ContestPage = () => import("@/pages/Marketing/ContestPage/Index.vue"); // No idea

//Meta remarketing
const CustomAudience = () =>
  import("@/pages/Marketing/CustomAudience/Index.vue");
const InstaAnalytics = () => import("@/pages/Insta/Index.vue");

// Meta offline
const NewAudience = () => import("@/pages/NewAudience/Index.vue");
//Google reviews

const GoogleReviews = () => import("@/pages/Marketing/GoogleReviews/Index.vue");

//Membership Page
const Membership = () => import("@/pages/Membership/Index.vue");
const SpinTheWheel = () => import("@/pages/Marketing/SpinTheWheel/Index.vue");

//Account Credits Page
const AccountCredits = () => import("@/pages/Billing/AccountCredits/Index.vue");

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true,
      requiresBrand: true,
    },
  },
  {
    path: "/brand-deactivated",
    name: "DeactivatedWarning",
    component: DeactivatedWarning,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/marketing-page",
    name: "MarketingButton",
    component: MarketingButton,
    meta: {
      requiresAuth: true,
      requiresBrand: true,
    },
  },
  {
    path: "/debug",
    name: "Debug",
    component: Debug,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/point-settings",
    name: "STPC",
    component: SpendingPointConversion,
    meta: {
      requiresAuth: true,
      requiresBrand: true,
    },
  },
  {
    path: "/onboarding",
    name: "Location",
    component: () =>
      import("@/pages/AccountManagement/UserOnboarding/Index.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/analytics/members",
    name: "MemberAnalytics",
    component: MemberAnalytics,
    meta: {
      requiresAuth: true,
      requiresBrand: true,
    },
  },
  {
    path: "/reward-settings",
    name: "RewardPage",
    component: RewardPage,
    meta: {
      requiresAuth: true,
      requiresBrand: true,
    },
  },
  {
    path: "/invite/:token",
    name: "InviteToken",
    component: CreateAccount,
    props: true,
  },
  {
    path: "/invite",
    name: "Invite",
    component: CreateAccount,
    props: true,
  },
  // {
  //   path: "/create-account",
  //   name: "CreateAccount",
  //   component: CreateAccount,
  // },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/link-brand/:brand_name/:brand_id/:token",
    name: "LinkBrand",
    component: LinkBrand,
    props: true,
  },
  {
    path: "/auth-reset",
    name: "AuthEmail",
    component: AuthEmail,
  },
  {
    path: "/reset-password/:token",
    name: "ResetPassword",
    component: ResetPassword,
    props($route) {
      return {
        token: $route.params.token,
      };
    },
  },
  {
    path: "/reset-password-expired",
    name: "ResetPasswordExpired",
    component: ResetPasswordExpired,
  },
  {
    path: "/verification-code/:email/:code",
    name: "VerificationCode",
    component: VerificationCode,
    //below is used for demo purposes only, need to remove this before production
    props($route) {
      return {
        email: $route.params.email,
        code: $route.params.code,
      };
    },
  },
  {
    path: "/verify-email/:email",
    name: "VerifyEmail",
    component: VerifyEmail,
    props($route) {
      return {
        email: $route.params.email,
      };
    },
  },
  {
    path: "/invalid-confirmation/:email",
    name: "InvalidConfirmation",
    component: InvalidConfirmation,
    props($route) {
      return {
        email: $route.params.email,
      };
    },
  },
  {
    path: "/choose-brand",
    name: "ChooseBrand",
    component: ChooseBrand,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/account/permissions",
    name: "UserPermissionPage",
    component: UserPermissionPage,
    meta: {
      requiresAuth: true,
      requiresBrand: true,
    },
  },
  {
    path: "/account/user-info",
    name: "Account User Info Page",
    component: AccountUserInfoPage,
    meta: {
      requiresAuth: true,
      requiresBrand: true,
    },
  },
  {
    path: "/account/store-security",
    name: "Security Code",
    component: StoreSecurity,
    meta: {
      requiresAuth: true,
      requiresBrand: true,
    },
  },
  {
    path: "/account/security-logs",
    name: "Security Logs",
    component: SecurityLogs,
    meta: {
      requiresAuth: true,
      requiresBrand: true,
    },
  },
  {
    path: "/account/add-store",
    name: "Add Store",
    component: ResetStore,
    meta: {
      requiresAuth: true,
      requiresBrand: true,
    },
  },
  {
    path: "/integration/marketing",
    name: "Marketing",
    component: Marketing,
    meta: {
      requiresAuth: true,
      requiresBrand: true,
    },
  },
  {
    path: "/integration/marketing-v2",
    name: "MarketingV2",
    component: MarketingV2,
    meta: {
      requiresAuth: true,
      requiresBrand: true,
    },
  },
  {
    path: "/integration/loyalty-program",
    name: "Loyalty Program",
    component: LoyaltyProgram,
    meta: {
      requiresAuth: true,
      requiresBrand: true,
    },
  },
  {
    path: "/account/languages",
    name: "Languages",
    component: LanguagePage,
    meta: {
      requiresAuth: true,
      requiresBrand: true,
    },
  },
  {
    path: "/user-onboarding",
    name: "User Onboarding",
    component: UserOnboarding,
    meta: {
      requiresAuth: true,
      requiresBrand: true,
    },
  },
  {
    path: "/get-started",
    name: "Get Started",
    component: GetStarted,
    meta: {
      requiresAuth: true,
      requiresBrand: true,
    },
  },
  {
    path: "/broadcast-message",
    name: "BroadcastMessage",
    component: MessageBroadcast,
    meta: {
      requiresAuth: true,
      requiresBrand: true,
    },
  },
  {
    path: "/broadcast-message/reports/:specification/:objectId",
    name: "BroadcastMessageReport",
    component: MessageBroadcastReport,
    meta: {
      requiresAuth: true,
      requiresBrand: true,
    },
    props: true,
  },

  {
    path: "/automation/reports/:campaignId",
    name: "AutomationReport",
    component: AutomationReport,
    meta: {
      requiresAuth: true,
      requiresBrand: true,
    },
    props: true,
  },
  {
    path: "/marketing-setting",
    name: "Marketing Setting",
    component: MarketingSetting,
    meta: {
      requiresAuth: true,
      requiresBrand: true,
    },
  },
  {
    path: "/automated-message",
    name: "Automation Page",
    component: AutomationPage,
    meta: {
      requiresAuth: true,
      requiresBrand: true,
    },
  },
  {
    path: "/acquisition-page",
    name: "AcquisitionPage",
    component: AcquisitionPage,
    meta: {
      requiresAuth: true,
      requiresBrand: true,
    },
  },
  {
    path: "/social-media",
    name: "Social Media",
    component: SocialMedia,
    meta: {
      requiresAuth: true,
      requiresBrand: true,
    },
  },
  {
    path: "/landing-page",
    name: "Landing Page",
    component: LandingPage,
    meta: {
      requiresAuth: true,
      requiresBrand: true,
    },
  },
  {
    path: "/analytics/marketing/landing-page",
    name: "Landing Page Analytics",
    component: LandingPageAnalytics,
    meta: {
      requiresAuth: true,
      requiresBrand: true,
    },
  },
  {
    path: "/contest-page",
    name: "ContestPage",
    component: ContestPage,
    meta: {
      requiresAuth: true,
      requiresBrand: true,
    },
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/referral",
    name: "Referral",
    component: Referral,
    meta: {
      requiresAuth: true,
      requiresBrand: true,
    },
  },
  {
    path: "/subscriptions",
    name: "Subscriptions",
    component: Subscriptions,
    meta: {
      requiresAuth: true,
      requiresBrand: true,
    },
  },
  {
    path: "/auto-recharge",
    name: "AutoRecharge",
    component: AutoRecharge,
    meta: {
      requiresAuth: true,
      requiresBrand: true,
    },
  },
  {
    path: "/members",
    name: "MembersPage",
    component: MembersPage,
    meta: {
      requiresAuth: true,
      requiresBrand: true,
    },
  },
  {
    path: "/analytics/monthly-reports",
    name: "Monthly Reports",
    component: MonthlyReports,
    meta: {
      requiresAuth: true,
      requiresBrand: true,
    },
  },
  {
    path: "/analytics/marketing",
    name: "Marketing Analytics",
    component: MarketingAnalytics,
    meta: {
      requiresAuth: true,
      requiresBrand: true,
    },
  },
  {
    path: "/analytics/members",
    name: "Member Analytics",
    component: MemberAnalytics,
    meta: {
      requiresAuth: true,
      requiresBrand: true,
    },
  },
  {
    path: "/vip-circles",
    name: "VIP Circles",
    component: VipCircles,
    meta: {
      requiresAuth: true,
      requiresBrand: true,
    },
  },
  {
    path: "/subscriptions",
    name: "Subscriptions",
    component: Subscriptions,
    meta: {
      requiresAuth: true,
      requiresBrand: true,
    },
  },
  {
    path: "/analytics/members",
    name: "Member Analytics",
    component: MemberAnalytics,
    meta: {
      requiresAuth: true,
      requiresBrand: true,
    },
  },
  {
    path: "/coming-soon",
    name: "Coming Soon",
    component: ComingSoon,
    meta: {
      requiresAuth: true,
      requiresBrand: true,
    },
  },
  {
    path: "/meta/existing",
    name: "Custom Audience",
    component: CustomAudience,
  },
  // Whatsapp Template
  {
    path: "/whatsapp-templates",
    name: "Whatsapp Templates",
    component: WhatsappTemplate,
    meta: {
      requiresAuth: true,
      requiresBrand: true,
    },
  },
  // messages onhold
  {
    path: "/messages-onhold",
    name: "Messages On Hold",
    component: MessagesonHold,
    meta: {
      requiresAuth: true,
      requiresBrand: true,
    },
  },
  {
    path: "/meta/new",
    name: "New Audience",
    component: NewAudience,
  },
  {
    path: "/google-reviews",
    name: "Google Reviews",
    component: GoogleReviews,
    meta: {
      requiresAuth: true,
      requiresBrand: true,
    },
  },
  {
    path: "/membership-program",
    name: "Membership",
    component: Membership,
    meta: {
      requiresAuth: true,
      requiresBrand: true,
    },
  },
  // {
  //   path: "/giveaway",
  //   name: "Giveaway",
  //   component: Giveaway,
  //   meta: {
  //     requiresAuth: true,
  //     requiresBrand: true,
  //   },
  // },
  {
    path: "/analytics/instagram",
    name: "Instagram",
    component: InstaAnalytics,
  },
  {
    path: "/spin-the-wheel",
    name: "SpinTheWheel",
    component: SpinTheWheel,
    meta: {
      requiresAuth: true,
      requiresBrand: true,
    },
  },
  {
    path: "/member-portal",
    name: "MemberPortal",
    component: MemberPortal,
    meta: {
      requiresAuth: true,
      requiresBrand: true,
    },
  },
  {
    path: "/custom-audience",
    name: "CustomAudience",
    component: CustomAudience,
    meta: {
      requiresAuth: true,
      requiresBrand: true,
    },
  },
  {
    path: "/new-audience",
    name: "NewAudience",
    component: NewAudience,
    meta: {
      requiresAuth: true,
      requiresBrand: true,
    },
  },
  {
    path: "/account-credits/:sessionId?/:successfulRecharge?",
    name: "Account Credits",
    component: AccountCredits,
    meta: {
      requiresAuth: true,
      requiresBrand: true,
    },
  },
  {
    path: "/credit-history",
    name: "CreditHistory",
    component: CreditHistory,
    meta: {
      requiresAuth: true,
      requiresBrand: true,
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        top: 0,
      };
    }
  },
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const result = await setCurrentUser();
    if (result === false) {
      next({ name: "Login" });
    } else {
      if (to.matched.some((record) => record.meta.requiresBrand)) {
        if (cookie.getSecureCookie("mulah_br") === false) {
          next({ name: "ChooseBrand" });
        } else if (localStorage.getItem("brandDeactivated") == "true") {
          next({ name: "DeactivatedWarning" });
        } else {
          next();
        }
      } else {
        next();
      }
    }
  } else {
    next();
  }
});

router.beforeResolve(async (to) => {
  const store = useBrandStore();
  if (to.meta.requiresBrand) {
    await store.getBrand();
  }
});

router.onError((error) => {
  if (/loading chunk \d* failed./i.test(error.message) && navigator.onLine) {
    window.location.reload();
  }
});

export default router;
