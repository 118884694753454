/* eslint-disable */
import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { useBrandStore } from "@/stores/brand.js";
import {
  formatFEtoBESms,
  formatFEToDisplay,
  validCountrySmsRestriction,
} from "@/constants/smsUtils.js";

export const useSmsScreenStore = defineStore("smsScreen", () => {
  const refetch = ref(false);
  const query = ref(null);
  const step = ref(0);
  const campaignType = ref(null);
  const filteringOption = ref([]);
  const selectedUsers = ref(0);
  const brandStore = useBrandStore();
  const overallUsers = ref(0);
  const brandId = ref(null);
  const brandName = ref(null);
  const countryCode = ref("MY");
  const foreignEnabled = ref(false);
  const filterArgs = ref({
    spec: null,
    opt: null,
  });
  const msgArgs = ref({
    title: "",
    expiryDate: "",
    smsContentInput: "",
    namePlaceholder: "",
  });
  const promotionArgs = ref({});
  const announcementArgs = ref({});
  const scheduleArgs = ref({});
  const storeList = ref([]);
  const membershipList = ref([]);
  const modal = ref(null);
  const smsTest = ref(false);
  const blacklistedUsers = ref([]);
  const blacklistedNumbers = ref([]);
  const broadcastSent = ref(false);
  const maxValidSms = ref(0);
  const lastVisitDates = ref([]);

  const clearFile = ref(() => {}); // use to clear uploaded file to reset file upload

  function startOver() {
    refetch.value = true;
    step.value = 0;
    campaignType.value = null;
    filteringOption.value = [];
    selectedUsers.value = 0;
    filterArgs.value = {
      spec: null,
      opt: null,
    };
    msgArgs.value = {
      title: "",
      expiryDate: "",
      smsContentInput: "",
      namePlaceholder: "",
    };
    promotionArgs.value = {};
    announcementArgs.value = {};
    scheduleArgs.value = {};
    smsTest.value = false;
    broadcastSent.value = false;
    //BOOTSTRAP hide does not get rid of modal backdrop by default
    if (modal.value) {
      modal.value._element.addEventListener("shown.bs.hidden", (event) => {
        modal.value.dispose();
      });
      modal.value.hide();
      modal.value = null;
    }
  }

  const navSteps = ref([
    {
      id: 1,
      name: "Choose Audience",
    },
    {
      id: 2,
      name: "Campaign Details",
    },
    {
      id: 3,
      name: "Message Content",
    },
    {
      id: 4,
      name: "Schedule Broadcast",
    },
    {
      id: 5,
      name: "Confirmation",
    },
  ]);

  const validMsgArgs = computed(() => {
    const validTitle = msgArgs.value.title;
    const validDate = msgArgs.value.expiryDate;

    if (campaignType.value == "manual") {
      return validTitle && validDate;
    }

    return validDate;
  });

  const validPromotionArgs = computed(() => {
    const validName = promotionArgs.value.name !== "";
    const validCost = promotionArgs.value.costStructureType !== "";
    const validValue =
      promotionArgs.value.costStructureValue !== "" &&
      !isNaN(promotionArgs.value.costStructureValue);

    if (Object.keys(promotionArgs.value).length === 0) {
      return false;
    } else {
      return validName && validCost && validValue;
    }
  });

  const validAnnouncementArgs = computed(() => {
    if (Object.keys(announcementArgs.value).length === 0) {
      return false;
    } else {
      return (
        announcementArgs.value.trackingExpiry !== "" &&
        announcementArgs.value.trackingExpiry !== null
      );
    }
  });

  const validSchedule = computed(() => {
    const validType = scheduleArgs.value.scheduleType != "";
    const validTiming =
      scheduleArgs.value.scheduleType == "now"
        ? true
        : scheduleArgs.value.sendDate !== "" &&
          scheduleArgs.value.sendTime !== "";

    if (Object.keys(scheduleArgs.value).length === 0) {
      return false;
    } else {
      return validType && validTiming;
    }
  });

  const canForward = computed(() => {
    if (step.value === 0) {
      return campaignType.value !== null;
    } else if (step.value === 1) {
      return filteringOption.value.length > 0;
    } else if (step.value === 2) {
      return (
        filterArgs.value.spec !== null &&
        filterArgs.value.opt !== null &&
        selectedUsers.value > 0
      );
    } else if (step.value === 3) {
      if (campaignType.value === "announcement") {
        return validAnnouncementArgs.value && msgArgs.value.title;
      } else {
        return validPromotionArgs.value && validMsgArgs.value;
      }
    } else if (step.value === 4) {
      return (
        validCountrySmsRestriction(
          brandStore.brand.countryCode,
          dbMessage.value
        ) && msgArgs.value?.validSms
      );
    } else if (step.value === 5) {
      return validSchedule.value;
    } else if (step.value === 6) {
      return (
        maxValidSms.value >= selectedUsers.value &&
        smsTest.value &&
        !broadcastSent.value
      );
    } else {
      return false;
    }
    lastVisitDates.value = [];
  });

  function moveForward() {
    step.value = step.value + 1;
  }

  function moveBackward() {
    step.value = step.value - 1;
  }

  const inputMessage = computed(() => {
    return msgArgs.value.smsContentInput;
  });

  const fullMessage = computed(() => {
    return formatFEToDisplay(
      msgArgs.value.smsContentInput,
      promotionArgs.value.name,
      msgArgs.value.namePlaceholder
    );
  });

  const dbMessage = computed(() => {
    return formatFEtoBESms(msgArgs.value.smsContentInput);
  });

  function resetOnCampaignChg() {
    announcementArgs.value = {};
    promotionArgs.value = {};
    msgArgs.value = {};
  }

  function formattedFilterArgs(args) {
    let formattedFilter;

    if (args.spec == "filtering") {
      formattedFilter = args.opt.map((item) => {
        return {
          action: item.action,
          input: item.filter.type + ";" + item.filter.input,
        };
      });
    } else if (args.spec == "frequency") {
      formattedFilter = args.opt.map((item) => {
        let arg;
        switch (item) {
          case "firstVisits":
            arg = "frequency;equal;1";
            break;
          case "secondVisits":
            arg = "frequency;equal;2";
            break;
          case "thirdVisits":
            arg = "frequency;equal;3";
            break;
          case "multiVisits":
            arg = "frequency;more;3";
            break;
        }
        return {
          action: "include",
          input: arg,
        };
      });
    } else if (args.spec == "inactive") {
      formattedFilter = args.opt.map((item) => {
        const input = item.split(";")[1].split("_").join(";");
        let arg = "inactive;";

        if (item.split(";")[1].split("_").length > 1) {
          arg = arg + "between;" + input;
        } else {
          arg = arg + "more;" + `${parseInt(input) - 1}`;
        }

        return {
          action: "include",
          input: arg,
        };
      });
    } else if (args.spec == "total_spending") {
      formattedFilter = args.opt.map((item) => {
        return {
          action: "include",
          input: "spending;" + item,
        };
      });
    } else if (
        args.spec == "available_points" ||
            args.spec == "collected_points" ||
            args.spec == "stores" ||
            args.spec == "membership"
    ) {
        formattedFilter = args.opt.map((item) => {
            return {
                action: "include",
                input: `${args.spec};` + item,
            };
        });
    } else if(args.spec == 'age_group') {
        formattedFilter = args.opt.map((item) => {
            let arg;
            switch (item) {
          case "lessThan18":
            arg = "age_group;between;0;18";
            break;
          case "18to24":
            arg = "age_group;between;18;24";
            break;
          case "25to34":
            arg = "age_group;between;25;34";
            break;
          case "35to55":
            arg = "age_group;between;35;55";
            break;
          case "55above":
            arg = "age_group;more;55";
            break;
        }
            return {
                action: 'include',
                input: arg
            };
        });
    } else if (args.spec == 'last_visit') {
        formattedFilter = [
            {
                action: 'include',
                input: 'last_visit;' + args.opt
            }
        ];
    } else {
      formattedFilter = null;
    }

    return formattedFilter;
  }

  return {
    brandStore,
    overallUsers,
    brandId,
    brandName,
    countryCode,
    step,
    navSteps,
    campaignType,
    filteringOption,
    filterArgs,
    canForward,
    moveForward,
    moveBackward,
    msgArgs,
    promotionArgs,
    scheduleArgs,
    selectedUsers,
    storeList,
    inputMessage,
    fullMessage,
    modal,
    dbMessage,
    announcementArgs,
    smsTest,
    broadcastSent,
    resetOnCampaignChg,
    startOver,
    blacklistedUsers,
    blacklistedNumbers,
    refetch,
    query,
    clearFile,
    membershipList,
    maxValidSms,
    lastVisitDates,
    formattedFilterArgs,
    foreignEnabled,
  };
});
