<script setup>
import Provider from "./Provider.vue";
import { ref } from "vue";
import HandleCache from "@/HandleCache.vue";
const cacheBuster = ref();
import { BRAND } from "@/constants/graphql";
import { useQuery } from "@urql/vue";
import { useBrandStore } from "@/stores/brand.js";

const store = useBrandStore();
const result = useQuery({
  query: BRAND,
  variables: {},
  pause: true,
});

store.$patch({ query: result });
</script>

<template>
  <HandleCache ref="cacheBuster">
    <template v-slot="{ isLoading, isLatestVersionAvailable }">
      <h1 v-if="isLoading">Loading...</h1>
      <template v-else-if="isLatestVersionAvailable">
        <suspense>
          <Provider>
            <router-view></router-view>
          </Provider>
        </suspense>
      </template>
      <template v-else>
        {{ cacheBuster.clearCacheAndReload() }}
      </template>
    </template>
  </HandleCache>
</template>
