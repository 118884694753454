export const BRAND = `
query SlBrand {
  slBrand {
    id
    name
    countryCode
    role
    token
    name
    slug
    active
    stpc {
      id
      specification
      active
      modifier
      threshold
    }
    createdAt
    availableCredits
    hasOngoingChanceCampaign
    defaultSmsTemplate {
      broadcastMsg
      automationMsg
      acquisitionMsg
      chanceMsg
      igGiveawayMsg
      membershipTierMsg
      membershipTierWaMsg
      membershipTierVoucherMsg
      membershipBdayVoucherMsg
      verificationMsg
      redemptionMsg
      redemptionReminderMsg
      expiryReminderMsg
      trackedCampaignMsg
    }
    currentMessageRates {
      whatsappRate
      smsRate
    }
    messagePackage {
      amount
    }
    uniqueMembersInTransient
    daysTillDeletion
    messageInQueue
    totalUnsentMessages
    userSubscription{
      expiryDate
      phase
      registeredNameId
      downgradeOnExpiry
    }
  }
}
`;

export const BRAND_AVAILABLE_CREDITS = `
query SlBrand {
  slBrand {
    id
    name
    availableCredits
    overallUsers
    countryCode
    unsubscribedUsers {
      id
    }
    unsubscribedNumbers
  }
}
`;

export const BRAND_INFO = `
query SlBrand {
  slBrand {
    id
    name
    overallUsers
    countryCode
    unsubscribedUsers {
      id
    }
    unsubscribedNumbers
    brandConfiguration {
      id
      foreignBroadcastEnabled
    }
  }
}
`;

export const BRAND_COUNTRY = `
query SlBrand {
  slBrand {
    id
    countryCode
  }
}
`;

export const BRAND_SMS_FREQUENCY = `
query Brand($forBroadcast: Boolean) {
  slBrand {
    id
    groupedCustomerVisits(forBroadcast: $forBroadcast)
  }
}
`;

export const BRAND_SMS_INACTIVE = `
query Brand($forBroadcast: Boolean) {
  slBrand {
    id
    inactiveVisits(forBroadcast: $forBroadcast) {
      spec
      label
      value
    }
  }
}
`;

export const BRAND_SMS_LIFETIME = `
query Brand(
  $keyword: String!
  $input: String!
  $forBroadcast: Boolean
) {
  slBrand {
    id
    customersBySpending(
      keyword: $keyword
      input: $input
      forBroadcast: $forBroadcast
    )
  }
}
`;

export const BRAND_POINTS = `
query Brand(
  $keyword: String!
  $input: String!
  $spec: String!
  $forBroadcast: Boolean
) {
  slBrand {
    id
    customersByPoints(
      keyword: $keyword
      input: $input
      spec: $spec
      forBroadcast: $forBroadcast
    )
  }
}
`;

export const BRAND_OUTLETS_CUSTOMERS = `
query Brand(
  $input: String!
  $forBroadcast: Boolean
) {
  slBrand {
    id
    customersByOutlet(input: $input, forBroadcast: $forBroadcast)
  }
}
`;

export const UPDATE_STPC = `
mutation UpdateStpc(
  $threshold: Int!
  $modifier: Int!
  $specification: String!
){
  updateStpc(
    threshold: $threshold
    modifier: $modifier
    specification: $specification
  ) {
    result
    errors { message }
  }
}
`;

export const REGISTER_PROMOTION = `
 mutation RegisterPromotion(
  $title: String!,
  $cost: Int!,
  $brandId: String!,
  $specification: Int!,
  $monetaryCost: String!,
  $promotionType: String
 ) {
  registerPromotion(
    title: $title,
    cost: $cost,
    brandId: $brandId,
    specification: $specification,
    monetaryCost: $monetaryCost,
    promotionType: $promotionType
  ) {
    promotion {
      id
      title
    }

    errors {
      message
    }
  }
 }
`;

export const POINT_PROMOTIONS = `
  query Promotions(
    $sort: String,
    $direction: String,
    $filters: PromotionSearch,
  ) {
      brand: slBrand {
      id
      promotions(
        sort: $sort
        direction: $direction
        filters: $filters
      ) {
        id
        title
        cost
        monetaryCost
        active
        specification
        promotionType
        archived
        imageUrl
      }
    }
  }
`;

export const TOGGLE_ACTIVE = `
  mutation ToggleActive($id: ID!, $active: Boolean!) {
    togglePromotionActive(active: $active, id: $id) {
      promotion {
        id
        title
        active
      }

      errors {
        message
      }
    }
  }
`;

export const TOGGLE_ARCHIVE = `
  mutation TogglePromotionArchive($id: ID!, $archived: Boolean!) {
    togglePromotionArchive(archived: $archived, id: $id) {
      result
      errors {
        message
      }
    }
  }
`;

export const EDIT_MONETARY_COST = `
  mutation EditMonetaryCost($promotionId: ID!, $monetaryCost: Float!) {
    editPromotionMonetaryCost(promotionId: $promotionId, monetaryCost: $monetaryCost) {
      result
      errors {
        message
      }
    }
  }
`;

export const POINT_REDEMPTION_HISTORY = ` 
  query BrandRedemptions(
    $filters: RedemptionSearch
    $limit: Int
    $currentPage: Int!
    $sort: String!
    $direction: String!
  ) {
    slBrand {
      id
      overallRedemptions(filters: $filters)
      redemptions(
        filters: $filters
        limit: $limit
        currentPage: $currentPage
        sort: $sort
        direction: $direction
      ) {
        id
        promotion {
          id
          title
          cost
        }
        outlet {
          id
          name
        }
        customerId
        createdAt
        void
      }
    }
  }
`;

export const POINTS_COLLECTION_HISTORY = ` 
  query BrandCollections(
    $filters: CollectionSearch
    $limit: Int
    $currentPage: Int!
    $sort: String!
    $direction: String!
  ) {
    slBrand {
      id
      overallCollections(
        filters: $filters
      )
      collections(
        filters: $filters
        limit: $limit
        currentPage: $currentPage
        sort: $sort
        direction: $direction
      ) {
        id
        customerId
        createdIso
        collectedPoints
        spending
        outletId
        outletName
        expiryDays
        expired
      }
    }
  }
`;

export const BROADCAST_MESSAGE_HISTORY = ` 
  query GetBroadcastMessageHistory(
    $filters: BroadcastSearch
    $limit: Int!
    $currentPage: Int!
    $sort: String!
    $direction: String!
  ) {
    slBrand {
      broadcasts(
        limit: $limit
        currentPage: $currentPage
        sort: $sort
        direction: $direction
        filters: $filters
      ) {
          overallBroadcasts
          records {
            id
            campaignId
            type
            title
            sentDate
            expiryDate
            status
            recipients
            successful
            pending
            insufficient
            apiError
            redeemed
            conversions
            roi
            conversionPerc
            message
            promoTitle
          }
      }
    }
  }
`;

export const VOID_REDEMPTION = `
mutation VoidRedemption(
  $id: ID!,
) {
  voidRedemption(
    id: $id
  ) {
    result
    errors {
      message
    }
  }
}
`;

export const USER_BRANDS = `
query {
  userBrands {
      brandId
      name
      role
      status
      inviterName
  }
}
`;

export const USERS = `
query Users{
  users {
      id
      email
      lastSignInAt
      name
      status
      userPermissions {
          name
          read
          modify
          download
      }
      userRole
  }
}
`;

export const USER_BASIC_INFO = `
query Users{
  users {
      email
      name
  }
}
`;

export const SET_ACTIVE_BRAND = `
  mutation SetActiveBrand(
    $brandId: String!
  ) {
    setActiveBrand(
      brandId: $brandId
    ) {
      result
      name
      country
      currency
      active
      errors {
        message
      }
    }
  }
`;

export const CREATE_BRAND_AND_OUTLETS = `
mutation CreateBrandAndOutlets(
  $name: String!,
  $industry: String!,
  $countryCode: String!,
  $outlets: [OutletInput!]!
  $stpcThreshold: Int!
  $stpcModifier: Int!
  $stpcSpecification: String!
  $promotions: [PromotionInput!]!
  $timeOffset: Int!
) {
  createBrandAndOutlets(
    name: $name,
    industry: $industry,
    countryCode: $countryCode,
    outlets: $outlets
    stpcThreshold: $stpcThreshold
    stpcModifier: $stpcModifier
    stpcSpecification: $stpcSpecification
    promotions: $promotions
    timeOffset: $timeOffset
  ) {
      result
      brandId
      outletCredentials {
        name
        id
        password
      }
      errors {
        message
    }
  }
}
`;

export const CREATE_OUTLETS = `
  mutation CreateOutlets($outlets: [OutletInput!]!) {
    createOutlets(outlets: $outlets) {
      outletCredentials {
        name
        id
        password
      }
      result
      errors {
        message
      }
    }
  }
`;

export const VALID_PERMISSIONS = `
query ValidPermissions{
  validPermissions {
      code
      description
  }
}
`;

export const UPDATE_USER_PERMISSIONS = `
mutation UpdateUserPermissions($userId: String!, $permissions: String!){
  updateUserPermissions(userId: $userId, permissions: $permissions) {
      result
      errors{
          message
      }
  }
}
`;

export const SEND_INVITATION = `
mutation SendInvitation($email: String!, $roleName: String!){
  sendInvitation(email: $email, roleName: $roleName) {
      result
      errors{
          message
      }
  }
}
`;

export const ORGANIZE_REFERRAL_CAMPAIGN = `
mutation OrganizeReferralCampaign(
  $startDate: String!
  $endDate: String!
  $incentiveDate: String!
  $promotionTitle: String!
  $promotionCost: Float!
  $redemptionLimit: Int!
  $referralId: String!
  $redirectLink: String!
  $tnc: String!
  $smsMessage: String!
  $nameholder: String!
  $title: String!
) {
  organizeReferralCampaign(
    startDate: $startDate
    endDate: $endDate
    incentiveDate: $incentiveDate
    promotionTitle: $promotionTitle
    promotionCost: $promotionCost
    redemptionLimit: $redemptionLimit
    referralId: $referralId
    redirectLink: $redirectLink
    tnc: $tnc
    smsMessage: $smsMessage
    nameholder: $nameholder
    title: $title
  ) {
    referralId
    errors { message }
  }
}
`;

export const SINGLE_RECHARGE_SMS_CREDITS = `
mutation SmsRechargeSingle(
  $registeredNameId: ID!
  $amount: Int!
){
  smsRechargeSingle(
    registeredNameId: $registeredNameId
    amount: $amount
  ) {
    result
    checkoutUrl
    errors { message }
  }
}
`;

export const ACQUISITION_URL = `
query AcquisitionUrl {
  acquisitionUrl {
    url
  }
}
`;

export const REMOVE_USER_FROM_BRAND = `
mutation RemoveUserFromBrand($email: String!) {
  removeUserFromBrand(email: $email) {
    result
    errors {
      message
    }
  }
}
`;

export const AUTOMATION_LOGS = `
query AutomationLogs($currentPage: Int!, $sort: String!, $direction: String!, $limit: Int!, $searchField: String, $searchInput: String) {
  automationLogs(currentPage: $currentPage, sort: $sort, direction: $direction, limit: $limit, searchField: $searchField, searchInput: $searchInput) {
    totalRecords
    vouchers {
      redemptionDate
      redemptionOutlet
      messageType
      customer {
        id
      }
      createdAt
      sentDate
      campaign {
        specification
        filtrationMode
        promotions {
          title
        }
      }
    }
  }
}
`;

export const AUTOMATION_REDEMPTION_REMINDER = `
query AutomationReminder(
    $filters: PromotionSearch,
    $sort: String,
    $direction: String
  ) {
  slBrand {
    id
    promotions(
      filters: $filters
      sort: $sort
      direction: $direction
    ) {
      id
      title
      cost
      active
      toRemind
    }
    brandConfiguration {
      id
      redemptionReminderLimit
      reminderTemplate
      reminderNameholder
    }
  }
}
`;

export const UPDATE_REDEMPTION_REMINDER_LIMIT = `
mutation UpdateRedemptionReminderLimit($limit: Int!) {
  updateRedemptionReminderLimit(limit: $limit) {
    result
    errors {
      message
    }
  }
}
`;

export const TOGGLE_PROMOTION_TO_REMIND = `
mutation TogglePromotionToReminder($promotionId: ID!) {
  togglePromotionToRemind(promotionId: $promotionId) {
    result
    errors {
      message
    }
    promotion {
      id
      active
      toRemind
    }
  }
}
`;

export const UPDATE_REDEMPTION_REMINDER_TEMPLATE = `
mutation UpdateRedemptionReminderTemplate($newTemplate: String!, $nameholder: String) {
  updateRedemptionReminderTemplate(newTemplate: $newTemplate, nameholder: $nameholder) {
    result
    errors {
      message
    }
    brandConfiguration {
      id
      reminderTemplate
      reminderNameholder
    }
  }
}
`;

export const AUTOMATION_CAMPAIGNS = `
query AutomationCampaigns {
  automationCampaigns {
    id
    specification
    messageTypes
    smsMessageFormatted
    messageExpiryDays
    ongoing
    archived
    nameholder
    fbWsTemplateId
    filtrationMode
    promotions {
      monetaryCost
      title
      formattedType
    }
  }
}
`;

export const CREATE_AUTOMATION = `
mutation CreateAutomation(
  $automationType: String!,
  $incentiveType: String!,
  $channel: String!,
  $incentiveName: String!,
  $incentiveCost: Float!,
  $message: String!,
  $expiryDays: Int!,
  $namePlaceholder: String,
  $fbWsTemplateId: Int,
  $filtrationMode: String
 ) {
  createAutomation(
    automationType: $automationType,
    incentiveType: $incentiveType,
    channel: $channel,
    incentiveName: $incentiveName,
    incentiveCost: $incentiveCost,
    message: $message,
    expiryDays: $expiryDays,
    namePlaceholder: $namePlaceholder,
    fbWsTemplateId: $fbWsTemplateId,
    filtrationMode: $filtrationMode

  ) {
    result
    errors {
      message
    }
  }
 }
`;

export const UPDATE_AUTOMATION = `
mutation UpdateAutomation(
  $id: ID!,
  $channels: [String!]!,
  $incentiveCost: Float,
  $archived: Boolean!
 ) {
  updateAutomation(
    id: $id,
    channels: $channels,
    incentiveCost: $incentiveCost,
    archived: $archived
  ) {
    result
    errors {
      message
    }
  }
 }
`;

export const UPDATE_AUTOMATION_MESSAGE = `
mutation UpdateAutomationMessage(
  $id: ID!,
  $message: String!
 ) {
  updateAutomationMessage(
    id: $id,
    message: $message
  ) {
    result
    errors {
      message
    }
  }
 }
`;

export const UPDATE_AUTOMATION_EXPIRY_AND_NAMEHOLDER = `
mutation UpdateAutomationExpiryAndNameholder(
  $id: ID!,
  $expiryDays: Int!,
  $nameholder: String
 ) {
  updateAutomationExpiryAndNameholder(
    id: $id,
    expiryDays: $expiryDays,
    nameholder: $nameholder
  ) {
    result
    errors {
      message
    }
  }
 }
`;

export const ACCEPT_BRAND_INVITE = `
mutation AcceptBrandInvite($brandId: ID!) {
  acceptBrandInvite(brandId: $brandId) {
    result
    errors {
      message
    }
  }
}
`;

export const RECURRING_RECHARGE_SMS_CREDITS = `
mutation SmsRechargeRecurring(
  $registeredNameId: ID!
  $amount: Int!
  $autoRechargeThreshold: Int!
  $autoRechargeAmount: Int!
){
  smsRechargeRecurring(
    registeredNameId: $registeredNameId
    amount: $amount
    autoRechargeThreshold: $autoRechargeThreshold
    autoRechargeAmount: $autoRechargeAmount
  ) {
    result
    checkoutUrl
    errors { message }
  }
}
`;

export const CREATE_REGISTERED_NAME = `
mutation CreateRegisteredName(
  $name: String!
  $streetAddress: String!
  $postcode: String!
  $city: String!
  $state: String
  $reusePaymentInfo: Boolean
  $reuseRegNameId: ID
  $currentPage: String!
){
  createRegisteredName(
    name: $name
    streetAddress: $streetAddress
    postcode: $postcode
    city: $city
    state: $state
    reusePaymentInfo: $reusePaymentInfo
    reuseRegNameId: $reuseRegNameId
    currentPage: $currentPage
  ) {
    result
    id
    duplicate
    url
    errors { message }
  }
}
`;

export const CREATE_CONTEST = `
mutation CreateContest(
  $category: String!,
  $startDate: String!,
  $endDate: String!,
  $prizeName: String!,
  $prizeExpiryDate: String!,
  $winCriteria: Int!,
  $tnc: String!,
  $minVisit: Int,
  $minSpend: Int
 ) {
  createContest(
    category: $category,
    startDate: $startDate,
    endDate: $endDate,
    prizeName: $prizeName,
    prizeExpiryDate: $prizeExpiryDate,
    winCriteria: $winCriteria,
    tnc: $tnc,
    minVisit: $minVisit,
    minSpend: $minSpend
  ) {
    result
    errors {
      message
    }
  }
 }
`;

export const CONTESTS = `
query Contests {
  contests {
    id
    category
    startDate
    endDate
    prize
    prizeExpiryDate
    winner
    minSpend
    minVisit
    status
  }
}
`;

export const MEMBERS = `
query Members ($inputs: [CustomerFilterAttributes!], $limit: Int, $currentPage: Int!, $sort: String!, $direction: String!)
{
  slBrand {
    overallUsers
    fullCustomersByFilter(inputs: $inputs, limit: $limit, currentPage: $currentPage, sort: $sort, direction: $direction) {
      filteredUserCount
      customerIds
      customers {
        id
        name
        email
        phoneNumber
        extension
        birthdate
        availablePoints
        collectedPoints
        totalVisits
        totalSpending
        firstVisit
        lastVisit
        lastVisited
        collectionSpecification
        durationWithBrand
        lastContacted
        blacklist
      }
    }
  }
}
`;

export const CREATE_TRACKED_CAMPAIGN = `
mutation CreateTrackedCampaign(
  $campaignArgs: TrackedCampaignAttributes!,
  $promotionArgs: TrackedCampaignPromotionAttributes!
 ) {
  createTrackedCampaign(
    campaignArgs: $campaignArgs,
    promotionArgs: $promotionArgs
  ) {
    result
    id
    errors {
      message
    }
  }
 }
`;

export const CAMPAIGNS = `
query SlCampaigns($specification: String!){
  slCampaigns(specification: $specification){
    id
    title
    promotions{
      id
      title
      promotionType
    }
  }
}
`;

export const MANUAL_CAMPAIGN = `
query brandManualCampaigns($id: ID!) {
  brand(id: $id) {
      id
      name
      countryCode
      expiredAnnouncements {
        id
        title
        roi
      }

      expiredManualCampaign {
        id
        title
        roi
      }
    }
  }
`;

export const BRAND_REFERRALS = `
query brandReferrals(
  $index: Int!
  $outletId: ID
) {
  brandReferrals(
    index: $index
    outletId: $outletId
  ) {
    id
    title
    startDate
    endDate
    active
    revenue
    clicks
    conversions
    redemptions
  }
}
`;

export const BRAND_REFERRALS_CHARTS = `
query brandReferralsChart(
  $index: Int!
  $campaignId: ID!
) {
  brandReferralsChart(
    index: $index
    campaignId: $campaignId
  ) {
    id
    title
    startDate
    endDate
    active
    data {
        chartPeriod
        clicks
        redemptions
    }
  }
}
`;

export const SL_CUSTOMER = `
query SlCustomer($id: ID, $phoneNumber: String, $name: String){
  slCustomer(id: $id, phoneNumber: $phoneNumber, name: $name){
    id
    name
    email
    phoneNumber
    birthdate
    availablePoints
    collectedPoints
    totalVisits
    totalSpending
    firstCollection
    lastCollection
    lastVisited
    durationWithBrand
  }
}
`;

export const REGISTERED_NAMES = `
query SlRegisteredNames {
  slRegisteredNames {
    registeredNames {
      id
      name
      streetAddress
      postcode
      city
    }
    lastUsedId
  }
}
`;

export const COMPANY_NAMES = `
query CompanyNames {
  slRegisteredNames {
    registeredNames {
      id
      name
    }
  }
}
`;

export const UPDATE_USER_ROLE = `
mutation UpdateUserRole($id: ID!, $name: String, $permissions: String){
  updateUserRole(id: $id, name: $name, permissions: $permissions){
    result
    errors{
      message
    }
  }
}
`;

export const CREATE_INVOICE_SETTINGS = `
mutation CreateInvoiceSettings(
  $registeredNameId: ID!
  $emailPaidInvoice: Boolean!
  $emailAddress: String
){
  createInvoiceSettings(
    registeredNameId: $registeredNameId
    emailPaidInvoice: $emailPaidInvoice
    emailAddress: $emailAddress
  ) {
    result
    errors { message }
  }
}
`;

export const USER_ROLES = `
query UserRoles($excludeOwner: Boolean, $excludeAdmin: Boolean) {
  userRoles(excludeOwner: $excludeOwner, excludeAdmin: $excludeAdmin) {
    id
    name
    permissions {
      name
      read
      modify
      download
  }
  }
}
`;

export const TOGGLE_CAMPAIGN_ONGOING = `
mutation ToggleCampaignOngoing($id: ID!) {
  toggleCampaignOngoing(id: $id) {
    result
    errors {
      message
    }
  }
}
`;

export const BRAND_CUSTOMER_FILTER = `
query BrandCustomerFilter(
  $inputs: [CustomerFilterAttributes!]!
) {
  slBrand {
    id
    customersByFilter(inputs: $inputs)
  }
}
`;

export const BROADCAST_CUSTOMERS_BY_EXTENSION = `
query BroadcastCustomersByExtension(
  $inputs: [CustomerFilterAttributes!]!
  $exclusive: Boolean
) {
  slBrand {
    id
    broadcastCustomersByExtension(inputs: $inputs, exclusive: $exclusive)
  }
}
`;

export const BRAND_CUSTOMER_FILTER_WITH_ID = `
query BrandCustomerFilterWithId(
  $inputs: [CustomerFilterAttributes!]!
) {
  slBrand {
    id
    customersByFilter(inputs: $inputs)
  }
}
`;

export const SEND_SMS_TEST = `
mutation SendSmsTest(
  $numbers: [String!]!
  $smsMessage: String!
  $brandId: ID!
  $nameholder: String!
  $expiry: String
) {
  sendSmsTest(
    numbers: $numbers
    smsMessage: $smsMessage
    brandId: $brandId
    nameholder: $nameholder
    expiry: $expiry
  ) {
    result
    errors {
      message
    }
  }
}
`;

export const SEND_MANUAL_SMS = `
mutation SendManualSms(
  $brandId: ID!
  $filterArgs: SmsScreenFilterAttributes!
  $campaignArgs: SmsScreenCampaignAttributes!
  $scheduleArgs: SmsScreenScheduleAttributes!
  $promotionArgs: SmsScreenPromotionAttributes!
) {
  organizeManualCampaignV2(
  brandId: $brandId
  filterArgs: $filterArgs
  campaignArgs: $campaignArgs
  scheduleArgs: $scheduleArgs
  promotionArgs: $promotionArgs
  ) {
    result
    errors {
      message
    }
  }
}
`;

export const SEND_ANNOUNCEMENT_SMS = `
mutation SendAnnouncementSms(
  $brandId: ID!
  $filterArgs: SmsScreenFilterAttributes!
  $campaignArgs: SmsScreenCampaignAttributes!
  $scheduleArgs: SmsScreenScheduleAttributes!
) {
  organizeAnnouncementCampaignV2(
  brandId: $brandId
  filterArgs: $filterArgs
  campaignArgs: $campaignArgs
  scheduleArgs: $scheduleArgs
  ) {
    result
    errors {
      message
    }
  }
}
`;

export const GET_WHATSAPP_USERS_PROFILE = `
  query CustomerInformations($currentPage: Int!, $limit: Int!){
    customerInformations(currentPage: $currentPage, limit: $limit) {
      pictureUrl
      name
    }
  }
`;

export const GET_NEWSFEED = `
  query newsfeed($limit: Int!, $currentPage: Int!){
    newsfeed(limit: $limit, currentPage: $currentPage) {
      results {
        id
        redeemedPoints
        spending
        outlet {
          name
        }
        name
        pictureUrl
        createdAt
      }
      totalPages
    }
  }
`;

export const GET_TOP_MEMBERS = `
  query TopMembers{
    topMembers {
      id
      name
      pictureUrl
      totalSpending
      totalVisits
      createdAt
    }
  }
`;

export const GET_POINTS = `
query collectedPoints($index: Int!){
    collectedPoints(index: $index) {
      startDate
      records {
        monthStart
        totalAmount
        name
        message
      }
    }
  }`;

export const GET_REDEEMED = `
query pointsRedeemed($index: Int!){
    pointsRedeemed(index: $index) {
      startDate
      records {
        monthStart
        totalCost
        name
        message
      }
    }
  }`;

export const GET_ITEM = `
query ItemsRedeemed($period: String!, $outletId: ID){
    itemsRedeemed(period: $period, outletId: $outletId) {
      title
      count
      name
      message
    }
  }`;

export const GET_ACTIVE_HOURS = `
query ActiveHours($index: Int!){
    activeHours(index: $index) {
      count
      hours
      name
      message
    }
  }`;

export const GET_AVERAGE_DATA_COLLECTED = `
query MostActiveDays($index: Int!){
    mostActiveDays(index: $index) {                
      averageMondayCount
      averageTuesdayCount
      averageWednesdayCount
      averageThursdayCount
      averageFridayCount
      averageSaturdayCount
      averageSundayCount
      name
      message
    }
  }
`;

export const TOTAL_BRAND_CREDIT = `
query TotalBrandCredit {
  totalBrandCredit {
    amount
  }
}
`;

export const SMS_RECHARGE_COST = `
query SmsRechargeCost($amount: Int!) {
  smsRechargeCost(amount: $amount) {
    taxName
    tax
    costAfterTax
  }
}
`;

export const SMS_RECHARGE_COST_RECURRING = `
query SmsRechargeCostRecurring($amount: Int!) {
  smsRechargeCostRecurring(amount: $amount) {
    taxName
    tax
    costAfterTax
  }
}
`;

export const TOP_PERFORMING_PROMOTIONAL_BROADCAST = `
  query topPerformingPromotionalBroadcast($id: ID!, $timeRange: Int) {
    brand(id: $id) {
      name
      countryCode
      expiredManualCampaign(timeRange: $timeRange) {
        id
        title
        expiryDate
        sentDate 
        totalCollections
        roi
        returns
        targetAmount
      }
    }
  }
`;

export const NEW_MEMBERS_COUNT = `
  query newMemberReports(
    $period: Int!
    $outletId: ID
    ) { 
    newMemberReports(
      period: $period
      outletId: $outletId
      ) {
        reportMonth
        reportYear
        newMembers
        medianNewMembers
    }
  }
`;

export const MEMBERS_MONTHLY_VISIT_COUNT = `
  query MonthlyVisitReports(
    $period: Int!
    $outletId: ID
  ){
    monthlyVisitReports(
      period: $period
      outletId: $outletId
    ){
      reportMonth
      reportYear
      count1
      count2
      count3
      count4More
    }
  }
`;

export const MEMBERS_MONTHLY_VALUE_COUNT = `
  query monthlyValue(
    $period: Int!
    $outletId: ID
  ){
    monthlyValueReports(
      period: $period
      outletId: $outletId
    ){
      reportMonth
      reportYear
      count25
      count50
      count75
      count100
      quartile1Bound
      quartile2Bound
      quartile3Bound
      quartile4Bound
    }
  }
`;

export const MEMBERS_DATA_ACQUISITION_COST = `
  query DataAcquisitionCostReports(
    $period: Int!
    $outletId: ID
  ){
    dataAcquisitionCostReports(
      period: $period
      outletId: $outletId
    ) {
      reportMonth
      reportYear
      cost
      medianCost
    }
  }
`;

export const MEMBERS_RETURN_RATE = `
  query returnRate(
    $period: Int!
    $outletId: ID
  ){
    returnRateReports(
      period: $period
      outletId: $outletId
    ) {
      reportMonth
      reportYear
      returnRate
      medianReturnRate
    }
  }
`;

export const MEMBERS_LIFETIME_VALUE = `
  query LtvReports(
    $period: Int!
    $outletId: ID
  ) {
    ltvReports(
      period: $period
      outletId: $outletId
    ) {
      reportMonth
      reportYear
      ltv
      medianLtv
    }
  }
`;

export const MEMBERS_MOST_RECENT_PURCHASE = `
  query membersMrp($outletId: ID) {
    mrpReports(outletId: $outletId) {
      months03
      months36
      months69
      months912
      months1215
      months1518
      months1824
      months24More
    }
  }
`;

export const ACTIVE_MEMBERS = `
  query ActiveMembers(
    $outletId: ID
  ) {
    activeMembers (
      outletId: $outletId
    ) {
        brandId
        activeMembers
    }
  }
`;

export const BRAND_OUTLETS = `
query SlBrand {
  slBrand {
    outlets {
      id
      name
      active
      markedAsDelete
    }
  }
}
`;

export const AVERAGE_MEMBER_VISITS = `
query AverageMemberVisits (
  $period: Int!, 
  $outletId: ID
  ) {
  averageMemberVisits (
    period: $period, 
    outletId: $outletId
    ) {
      reportMonth
      reportYear
      movingTotalVisits
      movingTotalMembers
      movingTotalPoints
      medianMemberPoints
      averageMemberVisits
      medianMemberVisits
  }
}
`;

export const LATEST_MONTH_AVERAGE_VISITS = `
query CurrentAverageMemberVisit {
  currentAverageMemberVisit {
      reportMonth
      reportYear
      movingTotalVisits
      movingTotalMembers
      movingTotalPoints
      medianMemberPoints
      averageMemberVisits
  }
}
`;

export const CUSTOMER_SMS_LOGS = `
query CustomerSmsLogs($customerId: ID!, $hidden: Boolean!) {
  customerSmsLogs(customerId: $customerId, hidden: $hidden) {
    id
    brandName
    message
    datetime
    channel
  }
}
`;

export const MEMBER_ACTIVITIES = `
query MemberActivities($customerId: ID!) {
  memberActivities(customerId: $customerId) {
    datetime
    transactionType
    spending
    points
    storeName
    validated
    voided
  }
}
`;

export const FILTER_MEMBERS = `
query FilterMembers($filters: [FilterMembersCriteria!]!) {
  filterMembers(filters: $filters) {
    filteredCount
  }
}
`;

export const MONTHLY_REPORTS = `
  query MontlyReports($id: ID!, $year: Int!, $month: Int!) {
    brand(id: $id) {
      id
      monthlyReports(year: $year, month: $month)
      createdAt
    }
  }
`;

export const BRAND_PLAN_LIMITS = `
query BrandPlanLimit{
  brandPlanLimit {
    plan {
      name
    }
    messagesUsed
    messageLimit
    databaseUsed
    databaseLimit
    nextResetDays
    freeTrialUsed
  }
}
`;

export const BRAND_EXPIRIES = `
query BrandExpiries{
  slBrand {
    brandExpiries {
      days
      monthInterval
      startMonth
      active
      periods
    }
  }
}
`;

export const ADD_BRAND_EXPIRY = `
mutation addBrandExpiry(
  $year: Int!, 
  $monthInterval: Int!,
  $startMonth: Int
  ) {
  addBrandExpiry(
    year: $year, monthInterval: 
    $monthInterval, 
    startMonth: $startMonth
    ) {
    result
    errors {
      message
    }
  }
}
`;

export const GET_BRAND_EXPIRY = `
mutation previewBrandExpiry(
  $year: Int!, 
  $monthInterval: Int!, 
  $startMonth: Int!
  ) {
  previewBrandExpiry(
    year: $year, 
    monthInterval: $monthInterval, 
    startMonth: $startMonth
    ) {
      result
      errors {
        message
      }
  }
}
`;

export const WEEKLY_INSIGHT = `
  query BrandsInsight(
    $startDate: String
    $endDate: String
  ) {
    brandCampaignInsightWeekly(
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      weeklyInsights {
        outletId
        name
        week
        year
        roi
        smsRedeemed
        startDate
        endDate
      }
    }
  }
`;

export const MONTHLY_BROADCAST_ANALYTICS_REPORTS = `
query MonthlyBroadcastAnalyticsReports(
  $period: String!
  $outletId: ID
  ) {
  monthlyBroadcastAnalyticsReports(
    period: $period
    outletId: $outletId
    ) {
      reportMonth
      reportYear
      roi
      increasedProfit
      allSmsSent
      smsRedeemed
      totalCollection
      organizerId
      specification
      avgConversion
      startDate
      endDate
      incentive
  }
}
`;

export const WEEKLY_BROADCAST_ANALYTICS_REPORTS = `
query WeeklyBroadcastAnalyticsReports(
  $outletId: ID
  ) {
  weeklyBroadcastAnalyticsReports(
    outletId: $outletId
    ) {
      roi
      week
      totalCollection
      outletId
  }
}
`;

export const MONTHLY_INCENTIVE_ANALYTICS_REPORT = `
query MonthlyIncentiveAnalyticsReports(
  $period: String!
  $outletId: ID
  ) { 
  monthlyIncentiveAnalyticsReports(
    period: $period
    outletId: $outletId
    ) {
      reportMonth
      reportYear
      roi
      increasedProfit
      smsSent
      smsRedeemed
      smsExpired
      specification
      incentive
  }
}
`;

export const SPECIFIC_INCENTIVE_ANALYTICS_REPORT = `
query TargetedIncentiveAnalyticsReports(
  $campaignId: ID!
) {
  targetedMonthlyIncentiveAnalyticsReports(
    campaignId: $campaignId
  ) {
      roi
      increasedProfit
      reportMonthInt
      reportYear
      roiCount
      smsRedeemed
      smsSent
      visitPer
      totalCost
      roas
      outletName
      offerCost
      cogsCost
      smsCost
      salesPerc {
        cpPerc
        cpcPerc
        roasPerc
        reachPerc
        additionalSalesPerc
      }
  }
}
`;

export const GET_REPORTS_DETAILS = `
query reportDetails($outletId: ID, $startDate: String, $endDate: String) {
	reportDetails(outletId: $outletId, startDate: $startDate, endDate: $endDate) {
		date
		collections
		transactions
		newMember
	}
}
`;

export const OUTLET_MISS_REPORTS = `
query missReportOutlets($startDate: String, $endDate: String) {
  missReportOutlets(startDate: $startDate, endDate: $endDate) {
    outletId
    name
    dates
  }
}`;

export const GET_OUTLET_TABLES = `
query outletTables(
  $outletId: ID!
  ) {
  outletTables(outletId: $outletId) {
    id
    tableNumber
  }
}
`;

export const CREATE_OUTLET_TABLE = `
mutation createOutletTable(
  $tableNumber: String!
  $outletId: ID!
) {
  createOutletTable(
    tableNumber: $tableNumber
    outletId: $outletId
  ) {
    result
    errors {
      message
    }
  }
}
`;

export const DELETE_OUTLET_TABLE = `
mutation deleteOutletTable(
  $tableId: ID!
) {
  deleteOutletTable(
    tableId: $tableId
  ) {
    result
    errors {
      message
    }
  }
}
`;

export const TOGGLE_USHER_MODE = `
mutation toggleBrandUsherMode{
  toggleBrandUsherMode {
    result
    errors {
      message
    }
  }
}
`;

export const STORE_SECURITY_SETTINGS = `
query slBrand{
  slBrand {
    countryCode
    securityCode
    collectionLimit
    brandConfiguration {
      collectionTransaction
      redemptionTransaction
      redemptionFrequencyLimit
      redemptionItemLimit
    }
  }
}
`;

export const GENERATE_SECURITY_CODE = `
mutation generateBrandSecurityCode{
  generateBrandSecurityCode {
    errors {
      message
    }
  }
}
`;

export const TOGLLE_TRANSACTION_PACKAGE = `
mutation toggleTransactionPackage($spec: String!){
  toggleTransactionPackage(spec: $spec) {
    result
    errors {
      message
    }
  }
}
`;

export const ADD_COLLECTION_LIMIT = `
mutation addCollectionLimit($limit: Int!){
  addCollectionLimit(limit: $limit) {
    errors {
      message
    }
  }
}
`;

export const EDIT_REDEMPTION_SECURITY_CONFIG = `
mutation editRedemptionSecurityConfig(
  $frequencyLimit: Int
  $itemLimit: Int
){
  editRedemptionSecurityConfig(
    frequencyLimit: $frequencyLimit
    itemLimit: $itemLimit
  ) {
    result
    errors {
      message
    }
  }
}
`;

export const CUSTOMER_BRAND_BLACKLIST = `
query customerBrandBlacklist($spec: String) {
  customerBrandBlacklist(spec: $spec) {
    customer {
      id
      name
      phoneNumber
      totalVisits
      collectedPoints
    }
    createdAt
  }
}
`;

export const BLOCK_CUSTOMERS_FROM_COLLECTION = `
mutation blockCustomersFromCollection(
    $spec: String!, 
    $target: String!
  ){
  blockCustomersFromCollection(
    spec: $spec
    target: $target
  ) {
    result
    errors {
      message
    }
  }
}
`;

export const USER_REFERRAL_TOKEN = `
query Users{
  users {
      id
      email
      referralToken
  }
}
`;

export const REGISTRATION_REFERRAL = `
query registrationReferral {
  registrationReferrals {
    invitedEmails
    registeredEmails
  }
}
`;

export const REGISTER_NEW_REFERRAL = `
mutation registerNewReferral($email: String!) {
  registerNewReferral(email: $email) {
    result
    errors
  }
}
`;

export const UPDATE_REFERRAL_STATUS = `
mutation updateReferralStatus($email: String!, $referralToken: String!) {
  updateReferralStatus(email: $email, referralToken: $referralToken) {
    result
    errors {
      message
    }
  }
}
`;

export const CHECK_VALID_TOKEN = `
  mutation checkReferralToken($referralToken: String!) {
    checkReferralToken(referralToken: $referralToken) {
      result
      errors {
        message
      }
    } 
  }
`;

export const CREATE_EMAIL_SUBSCRIPTION = `
  mutation CreateEmailSubscription($email: String! $subscribed: Boolean!) {
    createEmailSubscription(email: $email, subscribed: $subscribed ) {
      result
      errors {
        message
      }
    }
  }
`;

export const SEND_UNTRACKED_MESSAGE = `
mutation SendUntrackedMessage($customerId: ID!, $message: String!, $messageType: String!) {
  sendUntrackedMessage(customerId: $customerId, message: $message, messageType: $messageType) {
    result
    errors {
      message
    }
  }
}
`;

export const SEND_TRACKED_MESSAGE = `
mutation SendTrackedMessage($campaignId: ID!, $customerId: ID!) {
  sendTrackedMessage(campaignId: $campaignId, customerId: $customerId) {
    result
    message
    errors {
      message
    }
  }
}
`;

export const TRACKED_CAMPAIGNS = `
query SlCampaigns($specification: String!){
  slCampaigns(specification: $specification){
    id
    title
    messageExpiryDays
    expiryDate
    messageTypes
    promotions{
      id
      title
      promotionType
    }
  }
}
`;

export const TOGGLE_PRIVACY_MODE = `
  mutation TogglePrivacyMode {
    togglePrivacyMode {
      result
      errors {
        message
      }
    }
  }`;

export const RESET_STORE_PASSWORD = `
  mutation ResetStorePassword($outletId: ID!) {
    resetStorePassword (outletId: $outletId) {
      result
      errors {
        message
      }
    }
  }`;

export const BRAND_CONFIGURATION = `
query SlBrand {
  slBrand {
    id
    perOutletRate
    underSubscription
    customerInquiry
    brandConfiguration {
      usherMode
      privacyMode
      redemptionOnly
      allowSkipEmail
      restrictRemarketing
      sbscAutomatedMsgs
      sbscBroadcastMsgs
      foreignBroadcastEnabled
    }
  }
}
`;

export const TOGGLE_AUTOMATED_MESSAGE = `
  mutation toggleSbscAutomatedMsgs {
    toggleSbscAutomatedMsgs {
      result
      errors {
        message
      }
    }
  }`;

export const TOGGLE_BROADCAST_MESSAGE = `
  mutation toggleSbscBroadcastMsgs {
    toggleSbscBroadcastMsgs {
      result
      errors {
        message
      }
    }
  }`;

export const TOGGLE_RESTRICT_REMARKETING = `
  mutation toggleRestrictRemarketing {
    toggleRestrictRemarketing {
      result
      errors {
        message
      }
    }
  }
`;

export const GET_BRAND_SLUG = `
  query SlBrand($brandId: ID!) {
    slBrand(brandId: $brandId) {
      slug
      membershipType
    }
  }
`;

export const MONTHLY_AUTOMATION_REPORT = `
query MonthlyAutomationReports(
  $period: Int!
  $outletId: ID
  ) { 
  monthlyAutomationReports(
    period: $period
    outletId: $outletId
    ) {
      reportMonth
      reportYear
      roi
  }
}
`;

export const HIDDEN_PERMISSIONS = `
query HiddenPermissions{
  hiddenPermissions {
    name
    hiddenSubperms
  }
}
`;

export const UPDATE_POINTS_BALANCE_LIMIT = `
mutation UpdatePointsBalanceLimit(
  $pointsLimit: Int!
  ) { 
  updatePointsBalanceLimit(
    pointsLimit: $pointsLimit
    ) {
      result
      errors {
        message
      }
  }
}
`;

export const BRAND_CONFIGURATION_REMINDER = `
  query SlBrand {
    slBrand {
      brandConfiguration {
        pointReminderEnabled
        pointsBalanceLimit
        limitMaxPoints
      }
    }
  }
`;

export const TOGGLE_LIMIT_MAX_POINTS = `
  mutation ToggleLimitMaxPoints {
    toggleLimitMaxPoints {
      result
      errors {
        message
      }
    }
  }
}
`;

export const CUSTOMER_SEARCH = `
query CustomerSearch(
  $searchName: String!
) {
  customerSearch(
    searchName: $searchName
  ) {
    name
    id
  }
}
`;

export const VALIDATE_USER_CREDENTIALS = `
mutation validateUserCredentials($password: String!) {
  validateUserCredentials (password: $password) {
    result
    errors {
      message
    }
  }
}`;

export const DELETE_BRAND_OWNER = `
mutation deleteBrandOwner($userId: ID!) {
  deleteBrandOwner(userId: $userId) {
    result
    errors {
      message
    }
  }
}
`;

export const GENERATE_OWNER_VERIFICATION_CODE = `
mutation generateOwnerVerificationCode($newOwnerId: ID!){
  generateOwnerVerificationCode(newOwnerId: $newOwnerId) {
    result
    errors{
      message
    }
  }
}
`;

export const VALIDATE_OWNER_VERIFICATION_CODE = `
mutation validateOwnerVerificationCode($newOwnerId: ID!, $verificationCode: String!){
  validateOwnerVerificationCode(newOwnerId: $newOwnerId, verificationCode: $verificationCode) {
    result
    errors{
      message
    }
  }
}
`;

// Integrations
export const BRAND_ECOMMERCE_CONFIGURATION = `
  query BrandShopify {
    brand {
      id
      brandConfiguration {
        id
        shopifyEnabled
        magentoEnabled
      }
      magentoTemplates {
        websiteIds
        customerGroupIds
      }
    }
  }
`;

export const BRAND_ECOMMERCE_PROMOTIONS = `
  query BrandShopify {
    brand {
      id
      shopifyPromotions {
        id
        name
        discountAmount
        cost
        active
      }
      magentoPromotions {
        id
        name
        cost
        discountAmount
        active
      }
    }
  }
`;

// Xilnex
export const UPDATE_XILNEX_ID = `
  mutation updateXilnexId(
    $outletId: ID!
    $xilnexId: String!
  ) {
    updateXilnexId(
      outletId: $outletId
      xilnexId: $xilnexId
    ) {
      result
      errors {
        message
      }
    }
  }
`;

export const UPDATE_XILNEX_CREDENTIALS = `
  mutation updateXilnexCredentials(
    $appId: String!
    $appToken: String!
  ) {
    updateXilnexCredentials(
      appId: $appId
      appToken: $appToken
    ) {
      result
      verified
      updatedAt
      errors {
        message
      }
    }
  }
`;

export const TOGGLE_XILNEX_REDEMPTION_ENABLED = `
  mutation ToggleXilnexRedemptionEnabled($enabled: Boolean!) {
    toggleXilnexRedemptionEnabled(enabled: $enabled) {
      result
      errors {
        message
      }
    }
  }
`;

// Zeoniq
export const UPDATE_ZEONIQ_ID = `
  mutation UpdateZeoniqId(
    $outletId: ID!, 
    $zeoniqId: String!
  ) {
    updateZeoniqId (
      outletId: $outletId, 
      zeoniqId: $zeoniqId
    ) {
      result
      errors {
        message
      }
    }
  }
`;

// Shopify
export const CREATE_SHOPIFY_PROMOTION = `
  mutation CreateShopifyPromotion(
    $brandId: ID!
    $name: String!
    $display: String!
    $cost: Int!
    $discountAmount: Float!
    $shopifyProductId: String
    $minimumSpending: Float!
  ) {
    createShopifyPromotion(
      brandId: $brandId
      name: $name
      display: $display
      cost: $cost
      discountAmount: $discountAmount
      shopifyProductId: $shopifyProductId
      minimumSpending: $minimumSpending
    ) {
      result
      errors {
        message
      }
    }
  }
`;

export const TOGGLE_SHOPIFY_ENABLED = `
  mutation ToggleShopifyEnabled {
    toggleShopifyEnabled {
      result
      errors {
        message
      }
    }
  }
`;

export const TOGGLE_SHOPIFY_PROMOTION = `
  mutation ToggleShopifyPromotion(
    $id: ID!
  ) {
    toggleShopifyPromotion (
      id: $id
    ) {
      result
      errors {
        message
      }
    }
  }
`;

export const TOGGLE_SHOPIFY_COLLECTION_WEBHOOK = `
  mutation ToggleShopifyCollectionWebhook($enabled: Boolean!) {
    toggleShopifyCollectionWebhook(enabled: $enabled) {
      result
      errors {
        message
      }
    }
  }
`;

// Magento
export const UPDATE_MAGENTO_SETTINGS = `
  mutation UpdateMagentoSettings(
    $accessToken: String!
    $endpoint: String!
    $prefix: String!
  ) {
    updateMagentoSettings(
      accessToken: $accessToken
      endpoint: $endpoint
      prefix: $prefix
    ) {
      result
      verified
      errors {
        message
      }
    }
  }
`;

export const CREATE_MAGENTO_PROMOTION = `
  mutation CreateMagentoPromotion(
    $brandId: ID!
    $name: String!
    $display: String!
    $cost: Int!
    $discountAmount: Float!
    $websiteIds: [Int!]!
    $customerGroupIds: [Int!]!
  ) {
    createMagentoPromotion(
      brandId: $brandId
      name: $name
      display: $display
      cost: $cost
      discountAmount: $discountAmount
      websiteIds: $websiteIds
      customerGroupIds: $customerGroupIds
    ) {
      result
      errors {
        message
      }
    }
  }
`;

export const TOGGLE_MAGENTO_ENABLED = `
  mutation ToggleMagentoEnabled {
    toggleMagentoEnabled {
      result
      errors {
        message
      }
    }
  }
`;

export const TOGGLE_MAGENTO_PROMOTION = `
  mutation ToggleMagetoPromotion($id: ID!) {
    toggleMagentoPromotion(id: $id) {
      result
      errors {
        message
      }
    }
  }
`;

export const STORE_REPORT_RECIPIENTS = `
query StoreReportRecipients {
  storeReportRecipients {
      outletId
      outletName
      userIds
      outlet {
        id,
        active,
        markedAsDelete
      }
  }
}
`;

export const UPDATE_STORE_REPORT_RECIPIENTS = `
mutation UpdateStoreReportRecipients(
  $outletId: ID!
  $userIds: [ID!]!
) {
  updateStoreReportRecipients(
  outletId: $outletId
  userIds: $userIds
  ) {
    result
    errors {
      message
    }
  }
}
`;

export const BRAND_POINT_MULTIPLIER = `
  query BrandPointMultiplier {
    brandPointMultiplier {
      id
      starting
      ending
      multiplier
      active
      eventName
      rruleData
    }
  }
`;

export const TOGGLE_COLLECTION_EVENT = `
  mutation ToggleCollectionEvent($id: ID!, $active: Boolean!) {
    toggleCollectionEvent(id: $id, active: $active) {
      result
      errors {
        message
      }
    }
  }
`;

export const CREATE_COLLECTION_EVENT = `
  mutation CreateCollectionEvent(
    $starting: String!
    $ending: String!
    $multiplier: Float!
    $eventName: String!
    $rruleData: String
    ) {
    createCollectionEvent(
      starting: $starting
      ending: $ending
      multiplier: $multiplier
      eventName: $eventName
      rruleData: $rruleData
      ) {
        result
        errors {
          message
        }
    }
  }
`;

export const UPDATE_SMS_RECHARGE_RECURRING = `
  mutation UpdateSmsRechargeRecurring(
    $registeredNameId: ID!
    $autoRechargeThreshold: Int!
    $autoRechargeAmount: Int!
    ) {
    updateSmsRechargeRecurring(
      registeredNameId: $registeredNameId
      autoRechargeThreshold: $autoRechargeThreshold
      autoRechargeAmount: $autoRechargeAmount
      ) {
        result
        errors {
          message
        }
    }
  }
`;

export const AUTO_RECHARGE_CONFIG = `
query slBrand{
  slBrand {
    brandSmsLedger {
      smsRechargeThreshold
      smsRechargeAmount
      registeredNameId
    }
  }
}
`;

export const TOGGLE_REDEMPTION_ONLY = `
  mutation toggleRedemptionOnly {
    toggleRedemptionOnly {
      result
      errors {
        message
      }
    }
  }
`;

// Webhook
export const CREATE_WEBHOOK = `
  mutation createWebhook($specification: String!) {
    createWebhook(specification: $specification) {
      errors {
        message
      }
      webhook {
        id
        token
      }
    }
  }
`;

// Meta
export const TOGGLE_FB_CA_UPLOAD = `
  mutation ToggleFbCaUpload {
    toggleFbCaUpload {
      result
      errors {
        message
      }
    }
  }
`;

export const UPDATE_FB_SETTINGS = `
  mutation UpdateFbSettings(
    $token: String!
    $accountId: String!
  ) {
    updateFbSettings(
      token: $token, 
      accountId: $accountId
    ) {
      result
      verified
      updatedAt
      errors {
        message
      }
    }
  }
`;

// Shopee
export const REGISTER_SENDINBLUE_CREDS = `
mutation RegisterSendinblueCreds(
  $creds: String!
) {
  registerSendinblueCreds(
    creds: $creds
  ) {
    result
    verified
updatedAt
    errors {
      message
    }
  }
}
`;

export const EXTERNAL_INTEGRATION = `
query ExternalIntegration($spec: String!) {
  externalIntegration(spec: $spec) {
    id
    fb {
      audienceUpload
      verified
      adminSystemUserToken
      adsAccountId
      credentialsUploadTime
      wabaVerified
    }
    brevo {
      audienceUpload
      verified
      accessToken
      credentialsUploadTime
    }
  }
}
`;

export const EXTERNAL_OPS_INTEGRATION = `
query ExternalIntegration($spec: String!) {
  externalIntegration(spec: $spec) {
    id
    xilnex {
      appId
      appToken
      collectionEnabled
      redemptionEnabled
      verified
      credentialsUploadTime
    }
    wix {
      collectionEnabled
      redemptionEnabled
      verified
      credentialsUploadTime
      webhookToken
    }
    woocommerce {
      collectionEnabled
      redemptionEnabled
      verified
      credentialsUploadTime
      webhookToken
    }
    magento {
      collectionEnabled
      redemptionEnabled
      verified
      credentialsUploadTime
      webhookToken
      prefix
      endpoint
      accessToken
    }
    shopify {
      collectionEnabled
      redemptionEnabled
      verified
      credentialsUploadTime
    }
    zeoniq {
      collectionEnabled
      redemptionEnabled
      verified
      outletSettings
    }
  }
}
`;

export const LOGIN_ANNOUNCEMENT = `
query LoginAnnouncement {
  loginAnnouncement {
    id
    title
    imageUrl
  }
}
`;

export const CREATE_FB_CAMPAIGN = `
mutation CreateFbCampaign(
  $title: String!
  $description: String!
  $trackStart: String!
  $trackEnd: String!
  $audience: String!
  $outletIds: [ID!]
) {
    createFbCampaign(
      title: $title
      description: $description
      audience: $audience
      trackStart: $trackStart
      trackEnd: $trackEnd
      outletIds: $outletIds
    ) {
      result
      errors {
        message
      }
    }
}
`;

export const CREATE_FB_AUDIENCE = `
mutation CreateFbAudience(
  $title: String!
  $audience: String!
  $outletIds: [ID!]
) {
  createFbAudience(
    title: $title
    audience: $audience
    outletIds: $outletIds
  ) {
    result
    errors {
      message
    }
  }
}
`;

export const UPDATE_FB_CAMPAIGN = `
mutation UpdateFbCampaign(
  $id: ID!
  $campaignId: ID!
) {
    updateFbCampaign(
      id: $id
      campaignId: $campaignId
    ) {
      result
      errors {
        message
      }
    }
}
`;

export const FB_AUDIENCES = `
query FbAudiences {
  slBrand {
    id
    fbCustomAudiences {
      customAudienceId
      specification
      name
      total
      outletId
      campaignInfo {
        name
        approximateCount
        errors {
          message
        }
      }
    }
  }
}
`;

export const FB_CAMPAIGNS = `
query FbCampaigns($expired: Boolean, $archived: Boolean) {
  slBrand {
    id
    countryCode
    fbCampaigns(expired: $expired, archived: $archived) {
       id
       title
       starting
       ending
       audience
       size
       roi
       visits
       facebookCampaignIdentifier
       metadata
       campaignInfo {
         reach
         budget
         impressions
         costPerConversion
         conversion
         avgConversionValue
         roas
         caption
         imgUrl
         errors {
           message
         }
       }
    }
  }
}
`;

export const ARCHIVE_FB_CAMPAIGN = `
mutation ArchiveFbCampaign(
  $id: ID!
  $archived: Boolean!
) {
    archiveFbCampaign(
      id: $id
      archived: $archived
    ) {
      result
      errors {
        message
      }
    }
}
`;

export const FB_AUDIENCE = `
query FbAudience($outletIds: [ID!]) {
  slBrand {
    id
    rangeVisits(outletIds: $outletIds)
  }
}
`;

export const TOGGLE_CUSTOMER_INQUIRY = `
mutation ToggleCustomerInquiry($customerInquiry: String!){
  toggleCustomerInquiry(customerInquiry: $customerInquiry){
    result
    errors{
      message
    }
  }
}
`;

export const TOGGLE_EMAIL_MANDATORY = `
mutation ToggleEmailMandatory{
  toggleEmailMandatory{
    result
    errors{
      message
    }
  }
}
`;

export const BROADCAST_MESSAGE_REPORT = `
query BroadcastMessageReport($objectId: ID!, $specification: String!) {
  broadcastMessageReport(objectId: $objectId, specification: $specification) {
    title
    incentive
    startDate
    endDate
    grossProfit
    totalSpending
    smsRedeemed
    sentPercentage
    smsSent
    avgReturnStr
    roas
    cpc
    cogsCost
    offerCost
    smsCost
    membersVisitData
    membersPriorVisitData
    outletBreakdown
    currency
    dailyBreakdown
    salesPerc {
      cpPerc
      cpcPerc
      roasPerc
      reachPerc
      additionalSalesPerc
    }
  }
}
`;

export const UPDATE_REFERRAL_CAMPAIGN = `
  mutation UpdateReferralCampaign(
    $id: ID!
    $endTiming: String
    $redirectLink: String
    $referralId: String
    $smsMessage: String
    $nameholder: String
    $expiryDate: String
  ) {
    updateReferralCampaign(
      id: $id
      endTiming: $endTiming
      redirectLink: $redirectLink
      smsMessage: $smsMessage
      nameholder: $nameholder
      referralId: $referralId
      expiryDate: $expiryDate
    ) {
     result
     errors {
      message
    }
   }
  }
`;

export const ALL_REFERRAL_CAMPAIGNS = `
query AllReferralCampaigns {
  slBrand {
    allReferralCampaigns {
      id
      imageUrl
      title
      startTiming
      endTiming
      redemptionLimit
      smsSent
      returns
      conversionPercentage
      roi
      expiryDate
      redemptions
      adBudget
      salesPerConversion
      costPerConversion
      smsMessage
      nameholder
      status
      redirectLink
      referralId
      promotion {
        title
      }
    }
  }
}
`;

//fb ig analytics query
export const META_REPORTS = `
query MetaReports($period: Int!, $type: String!) {
  metaReports(period: $period, type: $type) {
    reportMonth
    reportYear
    additionalSales
    conversions
    totalVisits
    conversionPerc
    reach
    adBudget
  }
}
`;
export const GET_FB_GIVEAWAY_VERIFIED = `
query SlBrand {
  slBrand {
    id
    brandConfiguration {
      fbGiveawayVerified
    }
  }
}
`;

export const META_OVERVIEW = `
query MetaOverview($period: Int!) {
  metaOverview(period: $period) {
    additionalSales
    conversions
    reach
    conversionPerc
  }
}
`;

export const BRAND_OFFLINE_EVENT = `
query brandOfflineEvents {
  slBrand {
    countryCode
    fbOfflineEvents {
      id
      adId
      eventId
      archived
      lastTriggeredAt
      status
      campaignInfo {
        adName
        costPerConversion
        avgConversionValue
        conversion
        caption
        roas
        reach
        budget
        impressions
        uniquePurchase
        purchase
        purchaseAmnt
        dateStop
        dateStart
        imgUrl
        errors {
          message
        }
      }
    }
  }
}
`;

//expiry reminder
export const EXPIRY_REMINDER_CONFIG = `
query ExpiryReminderConfig($specification: String!) {
  slBrand {
    expiryReminderConfig {
      id
      frequencyEnabled
      frequencyOp
      frequencyLb
      frequencyHb
      inactiveMembersEnabled
      inactiveMembersOp
      inactiveMembersLb
      inactiveMembersHb
      lifetimeValueEnabled
      lifetimeValueOp
      lifetimeValueLb
      lifetimeValueHb
      smsMessage
      nameholder
      displayPromo(specification: $specification)
    }
  }
}
`;

export const UPDATE_OFFLINE_AD_ID = `
mutation UpdateOfflineAdId(
  $id: ID!
  $adId: String!
) {
  updateOfflineAdId(
    id: $id
    adId: $adId
  ) {
    result
    errors {
      message
    }
  }
}
`;

export const TOGGLE_EXPIRY_REMINDER_OPTIONS = `
mutation ToggleExpiryReminderOptions($option: String!, $enabled: Boolean!) {
  toggleExpiryReminderOptions(option: $option, enabled: $enabled) {
    result
    errors {
      message
    }
  }
}
`;

export const UPDATE_OFFLINE_EVENT_ID = `
mutation UpdateOfflineEventId(
  $id: ID!
  $eventId: String!
) {
  updateOfflineEventId(
    id: $id
    eventId: $eventId
  ) {
    result
    errors {
      message
    }
  }
}
`;

export const ARCHIVE_FB_OFFLINE = `
mutation ArchiveFbOffline(
  $id: ID!
  $archived: Boolean!
) {
  archiveFbOffline(
    id: $id
    archived: $archived
  ) {
    result 
    errors {
      message
    }
  }
}
`;

export const CREATE_FB_OFFLINE_EVENT = `
mutation CreateFbOfflineEvent(
  $adId: String!
  $eventId: String!
) {
  createFbOfflineEvent(
    adId: $adId
    eventId: $eventId
  ) {
    result
    errors {
      message
    }
  }
}
`;

export const ACTIVITY_LOG = `
query ActivityLogs(
  $limit: Int
  $currentPage: Int!
) {
  activityLogs(
  limit: $limit
  currentPage: $currentPage
  ) {
    user {
      id
      name
    }
    brand {
      id
    }
    operation
    appEnv
    target
    operationName
    metadata
    activityText
    createdAt
  }
}
`;

export const CREATE_REVIEW_CAMPAIGN = `
mutation CreateReviewCampaign (
  $reviewId: String!
  $enabledOutlets: [EnabledOutletInput!]!
  $title: String!
  $promotionType: String!
  $promotionCost: Int!
  $startDate: String!
  $endDate: String
  $redemptionLimit: Int
  $expiry: Int!
  $minSpendEnabled: Boolean!
  $minSpend: Int
  $defaultHashtags: [String!]
  $customHashtags: [String!]
  $promotionName: String
) {
  createReviewCampaign(
    reviewId: $reviewId
    enabledOutlets: $enabledOutlets
    title: $title
    promotionType: $promotionType
    promotionCost: $promotionCost
    startDate: $startDate
    endDate: $endDate
    redemptionLimit: $redemptionLimit
    expiry: $expiry
    minSpendEnabled: $minSpendEnabled
    minSpend: $minSpend
    defaultHashtags: $defaultHashtags
    customHashtags: $customHashtags
    promotionName: $promotionName
  ) {
    result
    errors {
      message
    }
    campaignId
  }
}
`;

export const BRAND_TOTAL_REVIEW_CAMPAIGNS = `
query BrandTotalReviewCampaigns {
  slBrand {
    totalReviewCampaigns
  }
}
`;

export const ACTIVE_REVIEW_OUTLETS = `
query ActiveReviewOutlets(
  $outletIds: [ID!]!
) {
  activeReviewOutlets(
    outletIds: $outletIds
  ) 
}
`;

export const REVIEW_CAMPAIGN_HISTORY = `
  query ReviewCampaigns(
    $limit: Int!
    $currentPage: Int!
    $sort: String!
    $direction: String!
    $filters: [String!]
    ) {
    reviewCampaigns(
      limit: $limit
      currentPage: $currentPage
      sort: $sort
      direction: $direction
      filters: $filters
    ) {
      totalRecords
      records {
        organizerId
        campaignId
        reviewId
        title
        status
        promotionName
        promotionCost
        promotionType
        createdAt
        startTiming
        endTiming
        minSpend
        enabledOutlets {
          id
          name
          placeId
          address
        }
        totalReviews
        totalOutlets
        redemptionLimit
        expiry
        totalPastMonthReviews
        additionalSales
        defaultHashtags
        customHashtags
        imageData
      }
    }
  }
`;

export const UPDATE_REVIEW_CAMPAIGN = `
  mutation UpdateReviewCampaign(
    $organizerId: ID!
    $endDate: String
    $redemptionLimit: Int
    $enabledOutlets: [EnabledOutletInput!]
    $expiry: Int
    $minSpend: Int
    $defaultHashtags: [String!]
    $customHashtags: [String!]
  ) {
    updateReviewCampaign(
      organizerId: $organizerId
      endDate: $endDate
      redemptionLimit: $redemptionLimit
      enabledOutlets: $enabledOutlets
      expiry: $expiry
      minSpend: $minSpend
      defaultHashtags: $defaultHashtags
      customHashtags: $customHashtags
    ) {
      result
      errors {
        message
      }
    }
  }
`;

export const CREATE_GIVEAWAY = `
mutation CreateGiveaway(
  $giveawayArgs: CreateGiveawayGiveawayAttributes!,
  $campaignArgs: CreateGiveawayCampaignAttributes!,
  $promotionArgs: CreateGiveawayPromotionAttributes!
  ) {
    createGiveaway(
      giveawayArgs: $giveawayArgs
      campaignArgs: $campaignArgs
      promotionArgs: $promotionArgs
    ) {
      result
      organizerId
      errors {
        message
      }
    }
  }`;

export const CREATE_EXPIRY_REMINDER_OPTION = `
mutation CreateExpiryReminderOption(
  $options: ExpiryReminderOptionsInput!
) {
  createExpiryReminderOption(options: $options) {
    result
    errors {
      message
    }
  }
}

input ExpiryReminderOptionsInput {
  key: String!
  operator: String!
  lowerBound: Int!
  higherBound: Int
}
`;

export const UPDATE_EXPIRY_REMINDER_ADVANCED = `
mutation UpdateExpiryReminderAdvanced(
  $smsMessage: String!
  $nameholder: String
) {
  updateExpiryReminderAdvanced(
    smsMessage: $smsMessage
    nameholder: $nameholder
  ) {
    result
    errors {
      message
    }
  }
}
`;

export const MEMBERSHIP = `
query SlMembership {
  membership {
    detailId
    timeLimit
    membershipTiers {
      id
      threshold
      name
      color
      notifyNewMembers
      rank
      smsMessage
      nameholder
    }
    membershipBenefits {
      id
      name
      specification
      benefitType
      category
      filtrationMode
      tierValues {
        tierName
        value
      }
    }
  }
}
`;

export const UPDATE_MEMBERSHIP = `
mutation UpdateMembership(
  $timeLimit: Int!
) {
  updateMembership(
    timeLimit: $timeLimit
  ) {
    result
    errors {
      message
    }
  }
}
`;

export const CREATE_MEMBERSHIP_BENEFIT = `
mutation CreateMembershipBenefit(
$detailId: ID!
$name: String!
$specification: String!
$category: String!
$benefitType: String!
$monetaryCost: Int!
$filtrationMode: String!
) {
  createMembershipBenefit(
    detailId: $detailId
    name: $name
    specification: $specification
    category: $category
    benefitType: $benefitType
    monetaryCost: $monetaryCost
    filtrationMode: $filtrationMode
  ) {
    result
    errors {
      message
    }
  }
}
`;

export const UPDATE_MEMBERSHIP_BENEFIT = `
mutation UpdateMembershipBenefit(
  $id: ID!
  $tierName: String!
  $tierValue: String!
) {
  updateMembershipBenefit(
    id: $id
    tierName: $tierName
    tierValue: $tierValue
  ) {
    result
    errors {
      message
    }
  }
}
`;

export const DELETE_MEMBERSHIP_BENEFIT = `
mutation DeleteMembershipBenefit(
$id: ID!
) {
  deleteMembershipBenefit(
    id: $id
  ) {
    result
    errors {
      message
    }
  }
}
`;

export const CREATE_MEMBERSHIP_TIER = `
mutation CreateMembershipTier(
  $detailId: ID!
  $title: String!
  $threshold: Int!
  $color: String!
) {
  createMembershipTier(
    detailId: $detailId
    title: $title
    threshold: $threshold
    color: $color
  ) {
    result
    errors {
      message
    }
  }
}
`;

export const UPDATE_MEMBERSHIP_TIER = `
mutation UpdateMembershipTier(
  $id: ID!
  $title: String
  $threshold: Int
  $default: Boolean
  $color: String
  $notifyNewMembers: Boolean
  $newMemberMsg: String
  $nameholder: String
) {
  updateMembershipTier(
    id: $id
    title: $title
    threshold: $threshold
    default: $default
    color: $color
    notifyNewMembers: $notifyNewMembers
    newMemberMsg: $newMemberMsg
    nameholder: $nameholder
  ) {
    result 
    errors {
      message
    }
  }
}
`;

export const DELETE_MEMBERSHIP_TIER = `
mutation DeleteMembershipTier(
  $id: ID!
  $detailId: ID!
) {
  deleteMembershipTier(
    id: $id
    detailId: $detailId
  ) {
    result
    errors {
      message
    }
  }
} 
`;

export const ENABLE_MEMBERSHIP = `
mutation EnableMembership(
  $spend: Float
  $spendType: String
) {
  enableMembership(
    spend: $spend
    spendType: $spendType
  )  {
    result
    errors {
      message
    }
  }
}
`;

export const ENABLE_EXPIRY_REMINDERS = `
mutation EnableExpiryReminders {
  enableExpiryReminders {
    result
    errors {
      message
    }
  }
}
`;

export const OVERALL_SPENDING = `
query OverallSpending {
  slBrand {
    overallSpending
    specification
  }
}
`;

export const DEMOTED_MEMBERS = `
query DemotedMembersOnDelete (
  $tierId: ID!
) {
    demotedMembersOnDelete (
      tierId: $tierId
    ) 
  } 
}
`;

export const FB_INSTA = `
query FbInsta {
  fbInsta {
    id
    username
    mediaCount
    followersCount
    mediaInfo
    storyInfo
    insightInfo
    commentsInfo
    replyableInfo
  }
}
`;

export const FB_INSTA_REPLY = `
mutation FbInstaReply(
  $commentId: ID!
  $message: String!
) {
  replyToInstaComment(
    commentId: $commentId
    message: $message
  ) {
    result
    errors {
      message
    }
  }
}
`;

export const CREATE_WS_TEMPLATE = `
mutation CreateWsTemplate(
  $textHeaderArgs: WsTemplateTextHeaderArgs
  $mediaHeaderArgs: WsTemplateMediaHeaderArgs
  $message: String!
  $buttonArgs: [WsTemplateButtonArgs!]
  $footer: String
  $name: String!
  $languageCode: String!
) {
  createWsTemplate(
    textHeaderArgs: $textHeaderArgs
    mediaHeaderArgs: $mediaHeaderArgs
    message: $message
    buttonArgs: $buttonArgs
    footer: $footer
    name: $name
    languageCode: $languageCode
  ) {
    result
    errors {
      message
    }
  }
}
`;

export const WS_TEMPLATES = `
query WsTemplates(
   $filters: WsTemplateSearch
   $limit: Int
   $currentPage: Int!
) {
  fbWsTemplates(
    filters: $filters
    limit: $limit
    currentPage: $currentPage
    
  ) {
    id
    name
    status
    fbTemplateId
    languageCode
    updatedAt
    overallCount
    bodyContent
  }
}
`;

export const WS_TEMPLATE = `
query WsTemplate($fbTemplateId: ID!) {
  fbWsTemplate(fbTemplateId: $fbTemplateId)
}
`;

export const SEND_TEST_WS = `
mutation SendTestWs(
  $templateId: ID!
  $phoneNumber: String!
  $extension: String!
) {
  sendTestWs(
    templateId: $templateId
    phoneNumber: $phoneNumber
    extension: $extension
  ) {
    result
    errors {
      message
    }
  }
}
`;

export const DELETE_WS_TEMPLATE = `
mutation DeleteWsTemplate(
  $fbTemplateId: ID!
  $name: String!
) {
  deleteWsTemplate(
    fbTemplateId: $fbTemplateId
    name: $name
  ) {
    result
    errors {
      message
    }
  }
}
`;

export const WABA_LINKED = `
  subscription {
    wabaLinked {
      status
      message
    }
  }
`;

export const GIVEAWAYS_HISTORY = `
query GiveawaysHistory($limit: Int!, $currentPage: Int!) {
  slBrand {
    id
    giveawayHistory(limit: $limit, currentPage: $currentPage) {
      totalRecords
      giveaways {
        id
        startTiming
        endTiming
        spendingThreshold
        friendTagThreshold
        shareTagPost
        rewardImageUrl
        conversions
        status
        additionalSales
        completedTasks
        totalRedemptions
        campaign {
          id
          title
          messageExpiryDays
          smsMessage
          nameholder
        }
        promotion {
          id
          title
          monetaryCost
        }
      }
    }
  }
}
`;

export const INSTA_BUSINESS_INFO = `
query InstaBusinessInfo {
  slBrand {
    id
    instaBusinessInfo {
      id
      username
      profilePictureUrl
    }
  }
}
`;

export const INSTA_USER_MEDIAS = `
query InstaUserMedias {
  slBrand {
    id
    instaBusinessMedias {
      id
      shortcode
      mediaUrl
      caption
      createdAt
    }
  }
}
`;
export const RATE_CHARGES = `
  query RateCharges {
    rateCharges {
      amount
      description
      smsRate
      whatsappRate
    }
  }
`;

export const CREATE_POST_LOGIN_ANALYTICS = `
mutation CreatePostLoginAnalytics (
  $date: String!
  $duration: Int!
  $action: String!
) {
  createPostLoginAnalytics (
    date: $date
    duration: $duration
    action: $action
  ) {
    result 
    errors {
      message
    }
  }
}
`;

export const CHANCE_DEFAULTS_QUERY = `
query ChanceDefaults {
  chanceDefaults {
    medianSpend
    chanceRecommendationResults{
      value
      probability
      promotionType
      prizeNameText
    }
    chanceIncentiveExpiry
  }
}
`;

export const CREATE_CHANCE_CAMPAIGN = `
 mutation CreateChanceCampaign($campaignInfo: ChanceCampaignInput!, $promotionInfo: [ChancePromotionInput!]!) {
      createChanceCampaign(campaignInfo: $campaignInfo, promotionInfo: $promotionInfo) {
      result
      errors {
        message
      }
    }
  }
`;

// Spin and Win History
export const CHANCE_CAMPAIGNS = `
query chanceCampaigns($limit: Int!, $currentPage: Int!, $sort: String!, $direction: String!) {
    chanceCampaigns(limit: $limit, currentPage: $currentPage, sort: $sort, direction: $direction) {
      totalRecords
      records {
        title
        campaignId
        campaignType
        status
        startDate
        endDate
        smsMessage
        minSpend
        expiry
        prizes {
          id
          name
          promotion {
            promotionType
            monetaryCost
          }
          probability
          won
          redeemed
          verifiedRedemptions
        }
        additionalSales
        averageLift
        medianSpend
        transactionsQualified
        spins
        spinsWon
        redeemed
      }
    }
  }
`;

export const TOGGLE_CHANCE_CAMPAIGN_STATUS = `
mutation ToggleChanceCampaignStatus(
  $campaignId: ID!
) {
  toggleChanceCampaignStatus(
    campaignId: $campaignId
  ) {
    result
    errors {
      message
    }
  }
}
`;

export const UPDATE_CHANCE_CAMPAIGN = `
mutation UpdateChanceCampaign(
  $campaignId: ID!
  $title: String
  $endDate: String
  $minSpend: Int
  $expiry: Int
) {
  updateChanceCampaign(
    campaignId: $campaignId
    title: $title
    endDate: $endDate
    minSpend: $minSpend
    expiry: $expiry
  ) {
    result
    errors {
      message
    }
  }
}
`;
export const TOGGLE_OUTLET_ACTIVE = `
mutation ToggleOutletActive (
  $outletId: ID!
) {
  toggleOutletActive(
    outletId: $outletId
  ) {
    result
    errors {
      message
    }
  }
}
`;

export const UPDATE_CHANCE_PRIZE = `
  mutation UpdateChancePrize(
    $id: ID!
    $type: String!
    $name: String!
    $value: Int!
    $probability: Float!
  ) {
    updateChancePrize(
      id: $id
      type: $type
      name: $name
      value: $value
      probability: $probability
    ) {
      result
      errors {
        message
      }
    }
  }
`;

export const UPDATE_CHANCE_PRIZES = `
  mutation UpdateChancePrizes(
    $campaignId: ID!
    $prizes: [ChancePromotionInput!]!
  ) {
    updateChancePrizes(
      campaignId: $campaignId
      prizes: $prizes
    ) {
      result
      errors {
        message
      }
    }
  }
`;

export const POST_LOGIN_ANNOUNCEMENT = `
query PostLoginAnnouncement {
  postLoginAnnouncement {
    id
    activated
    headline
    description
    content {
      title
      description
      enabled
      hyperlinkText
      hyperlinkUrl
    }
    mainButtonName
    mainButtonUrl
    secondaryButtonName
    secondaryButtonUrl
    urlToggle
    imageUrl
    descriptionLinkUrl
    descriptionLinkText
    enabledClose
  }
}
`;

export const MESSAGE_COUNT = `
query MessageCount($amount: Float!) {
  messageCount(amount: $amount) {
    smsCount
    whatsappCount
    waCostPrice
    paymentToWa
    paymentToSl
    paymentTotal
  }
}
`;

export const MEMBERS_PORTAL_DATA = `
query MembersPortal {
  slBrand{
    id
    membershipType
    membershipDetails {
      detailId
      membershipTiers {
        id
        name
        color
        rank
      }
    }
    brandPortalSetting {
      id
      colorPalette
      logoUrl
      logoUrl
      coverSpecification
      coverColor
      coverPhotoUrl
      carouselTitle
      carouselSpecification
      carouselSpacesCustom {
        index
        enabled
        caption
        description
        url
        imageUrl
      }
      carouselSpacesInsta {
        index
        enabled
        id
        caption
        description
        url
        imageUrl
      }
      links {
        index
        title
        enabled
        url
        emoji
      }
      lbSpecification
      lbColor
      lbGradient
      lbColorSpecification
      lbPhotoUrl
      lbOpacity
      topNavbarSpecification
      topNavbarColor
      topNavbarGradient
      topNavbarColorSpecification
      topNavbarPhotoUrl
      topNavbarOpacity
      topNavbarTextColor
      membersInfoSpecification
      membersInfoColor
      membersInfoTextColor
      membersInfoGradient
      membersInfoColorSpecification
      membersInfoPhotoUrl
      membersInfoOpacity
      generalBackgroundColor
      generalBackgroundGradient
      generalBackgroundColorSpecification
      primaryTextColor
      secondaryTextColor
      textLinkColor
      botNavbarBackgroundColor
      botNavbarDefaultColor
      botNavbarSelectedColor
      primaryBtnColor
      primaryBtnTextColor
      primaryBtnBorderColor
      secondaryBtnColor
      secondaryBtnTextColor
      secondaryBtnBorderColor
      storeListCardColor
      storeListCardTextColor
      linkColor
      linkTextColor
      linkBorderColor
    }
  }
}
`;

export const UPDATE_MEMBERS_PORTAL_SETTINGS = `
mutation UpdateMembersPortalSettings(
  $colorPalette: [String!]
  $coverPhoto: PortalSpecificationInput!
  $carouselTitle: String!
  $carouselSpecification: String!
  $carouselSpacesCustom: [CarouselSpacesInput!]
  $carouselSpacesInsta: [CarouselSpacesInput!]
  $links: [LinksInput!]!
  $landscapeBackground: PortalSpecificationInput!
  $membersInfo: TopNavbarInput!
  $topNavbar: TopNavbarInput!
  $colors: PortalColorsInput!
) {
  updateMembersPortalSettings(
    colorPalette: $colorPalette
    coverPhoto: $coverPhoto
    carouselTitle: $carouselTitle
    carouselSpecification: $carouselSpecification
    carouselSpacesCustom: $carouselSpacesCustom
    carouselSpacesInsta: $carouselSpacesInsta
    links: $links
    landscapeBackground: $landscapeBackground
    membersInfo: $membersInfo
    topNavbar: $topNavbar
    colors: $colors
  ) {
    result
    errors {
      message
    }
  }
}
`;

export const GENERATE_DISABLE_MEMBERSHIP_CODE = `
  mutation GenerateDisableMembershipCode {
    generateDisableMembershipCode {
      result
      errors {
        message
      }
    }
  }
`;

export const VALIDATE_DISABLE_MEMBERSHIP_CODE = `
 mutation ValidateDisableMembershipCode (
  $code: String!
 ) {
  validateDisableMembershipCode (
    code: $code
  ) {
    result
    errors {
      message
    }
  }
}
`;

export const DASHBOARD_TOOLTIPS = `
  query DashboardTooltips($location: String) {
    dashboardTooltips(location: $location) {
      id
      location
      language
      title
      content
      identifier
    }
  }
`;

export const BRAND_SMS_MEMBERS = `
query BrandSmsMembers($forBroadcast: Boolean) {
  slBrand {
    id
    groupedMembers(forBroadcast: $forBroadcast)
  }
}
`;

export const BRAND_MEMBERSHIPS = `
query BrandMemberships {
  slBrand {
    id
    memberships {
      id
      title
    }
  }
}
`;

export const CURRENT_MESSAGE_COUNT = `
query CurrentMessageCount($amount: Float!) {
  currentMessageCount(amount: $amount) {
    smsCount
    whatsappCount
    }
  }
`;

export const PROCESS_PAYMENT_SETUP = `
  mutation ProcessPaymentSetup($sessionId: String!) {
    processPaymentSetup(sessionId: $sessionId) {
      result
      errors {
        message
      }
    }
  }
`;

export const BRAND_CREDIT_USAGE = `
query BrandCreditUsage (
  $month: String!
  $year: String!
) {
  brandCreditUsage (
    month: $month
    year :$year
  ) {
    creditsUsed
    smsSent
    whatsappSent
    updatedAt
  }
}
`;

export const OUTLET_CREDIT_USAGE = `
query OutletCreditUsage (
  $month: String!
  $year: String!
) {
  outletCreditUsage (
    month: $month
    year: $year
  ) {
    outletName
    month
    year
    totalCredits
    smsCredits
    whatsappCredits
  }
}
`;

export const OUTLET_MESSAGE_SENT = `
query OutletMessageSent (
  $month: String!
  $year: String!
) {
  outletCreditUsage (
    month: $month
    year: $year
  ) { 
    outletName
    month
    year
    messagesSent
    smsSent
    whatsappSent
  }
}
`;

export const OUTLET_CREDIT_USAGE_SUM = `
query OutletCreditUsageSum (
  $period: Int!
  $outletId: ID
) {
  outletCreditUsageSum (
    period: $period
    outletId: $outletId
  ) {
    month
    year
    monthlyCredits
    smsCredits
    whatsappCredits
  }
}
`;

export const OUTLET_MESSAGE_USAGE_SUM = `
query OutletMessageUsageSum (
  $period: Int!
  $outletId: ID
) {
  outletCreditUsageSum (
    period: $period
    outletId: $outletId
  ) {
    month
    year
    messagesSent
    smsSent
    whatsappSent
  }
}
`;

export const OUTLET_CREDIT_USAGE_BREAKDOWN = `
query OutletCreditUsageBreakdown (
  $month: String
  $year: String
) {
  outletCreditUsageBreakdown (
    month: $month
    year: $year
  ) {
    month
    year
    monthlyCredits
    messagesSent
    automationCredits
    broadcastCredits
    verificationCredits
    eventCredits
  }
  }
`;

export const UPDATE_USER_INFO_NAME = `
 mutation UpdateUserInfoName (
  $name: String!
 ) {
  updateUserInfoName (
    name: $name
  ) {
    result
    errors {
      message
    }
  }
 }
`;

export const GENERATE_UPDATE_USER_INFO_CODE = `
 mutation GenerateUpdateUserInfoCode (
  $specification: ID!
  $newEmail: String
 ) {
  generateUpdateUserInfoCode (
    specification: $specification
    newEmail: $newEmail
  ) {
    result
    errors {
      message
    }
  }
 }
`;

export const VALIDATE_UPDATE_USER_INFO_PW = `
 mutation ValidateUpdateUserInfoPw (
  $token: String!
  $password: String!
  $passwordConfirmation: String!

 ) {
  validateUpdateUserInfoPw (
    token: $token
    password: $password
    passwordConfirmation: $passwordConfirmation

  ) {
    result
    errors {
      message
    }
  }
 }
`;

export const VALIDATE_UPDATE_USER_INFO_EMAIL = `
 mutation ValidateUpdateUserInfoEmail (
  $token: String!
  $email: String!

 ) {
  validateUpdateUserInfoEmail (
    token: $token
    email: $email

  ) {
    result
    errors {
      message
    }
  }
 }
`;

export const VALIDATE_UPDATE_USER_INFO_CODE = `
 mutation ValidateUpdateUserInfoCode (
  $specification: String!
  $code: String!
 ) {
  validateUpdateUserInfoCode (
    specification: $specification
    code: $code
  ) {
    result
    errors {
      message
    }
  }
 }
`;

export const DELETE_BRAND_LOGO = `
mutation DeleteBrandLogo {
  deleteBrandLogo {
    result
    errors {
      message
    }
  }
 }
`;

export const AUTOMATION_HISTORY = `
  query AutomationHistory(
    $currentPage: Int!
    $sort: String!
    $direction: String!
    $limit: Int!
    $startMonth: Int
    $startYear: Int
    $endMonth: Int
    $endYear: Int
  ) {
    automationHistory(
      currentPage: $currentPage
      sort: $sort
      direction: $direction
      limit: $limit
      startMonth: $startMonth
      startYear: $startYear
      endMonth: $endMonth
      endYear: $endYear
    ) {
      totalRecords
      records {
        id
        messageType
        archived
        messageExpiryDays
        createdAt
        smsMessage
        specification
        specificationNumber
        promotionTitle
        ongoing
        additionalSales
        messagesSent
        messagesDelivered
        messagesError
        messagesExpired
        messagesRedeemed
        conversions
      }
    }
  }
`;

export const CANCEL_ANNOUNCEMENT_BROADCASTS = `
  mutation CancelAnnouncementBroadcast($id: ID!) {
    cancelAnnouncementBroadcast(id: $id) {
      result
      errors {
        message
      }
    }
  }
  `;

export const CANCEL_MANUAL_BROADCASTS = `
  mutation CancelManualBroadcast($id: ID!) {
    cancelManualBroadcast(id: $id) {
      result
      errors {
        message
      }
    }
  }
  `;

export const SL_PLAN_NOTIFICATION_CONFIG = `
query SlPlanNotificationConfigs {
    slPlanNotificationConfigs {
      id
      specification
      bubbleTitle
      bubbleBody
      popupTitle
      popupSubtitle
      popupBody
    }
  }
`;

export const CREATE_TRIAL_SUBSCRIPTION = `
mutation CreateTrialSubscription(
  $registeredNameId: ID!
  $currentPage: String!
) {
  createTrialSubscription(
    registeredNameId: $registeredNameId
    currentPage: $currentPage
  ) {
    result
    url
    errors {
      message
    }
  }
}
`;

export const QUEUED_VOUCHERS = `
  query QueuedVouchers($currentPage: Int!, $limit: Int!, $filters: QueuedVoucherSearch) {
    queuedVouchers(currentPage: $currentPage, limit: $limit, filters: $filters) {
     totalRecords
      records {
        voucherId
        customerId
        createdAt
        specification
        smsMessage
        messageType
        voucherType
        transient
        incentiveName
        memberName
        expiryDate
      }
    }
  }
`;

export const RESEND_QUEUED_VOUCHER = `
  mutation ResendQueuedVouchers($vouchers: [ResendVouchersInput!]!) {
    resendQueuedVouchers(vouchers: $vouchers) {
      result 
      errors {
        message
      }
      countSent
    }
  }
`;

export const RESEND_ALL_QUEUED_VOUCHER = `
  mutation ResendAllQueuedVouchers {
    resendAllQueuedVouchers {
      result 
      errors {
        message
      }
      countSent
    }
  }
`;

export const CREATE_PAID_SUBSCRIPTION = `
mutation CreatePaidSubscription(
  $registeredNameId: ID!
  $currentPage: String!
) {
  createPaidSubscription(
    registeredNameId: $registeredNameId
    currentPage: $currentPage
  ) {
    result
    url
    errors {
      message
    }
  }
}
`;

export const CANCEL_SUBSCRIPTION = `
mutation CancelSubscription{
  cancelSubscription 
  {
    result
    errors {
      message
    }
  }
}
`;

export const OUTLET_PRICES = `
query SlBrand {
  slBrand {
    perOutletRate
  }
}
`;

export const ACTIVE_OUTLETS = `
query SlBrand {
  slBrand {
    activeOutlets{
      id
      name
    }
  }
}
`;

export const DASHBOARD_OVERVIEW_INFO = `
query DashboardOverviewInfo{
  slBrand {
    id
    overallUsers
    monthlyAdditionalSales
    monthlyOverallUsersRecord
  }
}
`;

export const REVIEW_CAMPAIGN_OUTLETS = `
query ReviewCampaignOutlets {
    reviewCampaignOutlets {
      id
      name
      active
      markedAsDelete
      placeId
      address
    }
  }
`;

export const UPDATE_LAST_VISITED_MARKETING = `
mutation UpdateLastVisitedMarketing {
  updateLastVisitedMarketing {
    result
    errors {
      message
    }
  }
}
`;

export const BRAND_AGE_GROUP = `
query Brand($forBroadcast: Boolean) {
  slBrand {
    id
    customersAgeGroup(forBroadcast: $forBroadcast)
  }
}
`;

export const BRAND_LAST_VISIT = `
query Brand(
  $keyword: String!
  $startDate: String!
  $endDate: String!
  $forBroadcast: Boolean
  ) {
  slBrand {
    id
    lastVisit(
      keyword: $keyword
      startDate: $startDate
      endDate: $endDate
      forBroadcast: $forBroadcast
    )
  }
}
`;

export const DASHBOARD_TRAINING_PROGRAM = `
query DashboardTrainingProgram{
  dashboardTrainingProgram{
    id
    title
    description
    dashboardTutorialPopups{
      id
      location
      title
      description
      videoUrl
      countdownTimer
      buttonText
      code
      enabled
    }
  }
}
`;

export const UPDATE_DASHBOARD_TRAINING_STATUS = `
  mutation UpdateDashboardTrainingStatus($dashboardTutorialPopupId : ID!  ) {
    updateDashboardTrainingStatus(dashboardTutorialPopupId : $dashboardTutorialPopupId ) {
      result
      errors {
        message
      }
    }
  }
`;

export const TOGGLE_FOREIGN_BROADCAST_MSGS = `
mutation ToggleForeignBroadcastMsgs {
    toggleForeignBroadcastMsgs {
        result
        errors {
            message
        }
    }
}
`;

export const USER_COMPLETED_TRAINING = `
query UserCompletedTrainingPopupIds{
  userCompletedTrainingPopupIds
}
`;

export const PLAN_LIMIT_CONFIG = `
query PlanLimits {
  planLimits {
    name
    databaseLimit
    messageLimit
    additionalDatabaseLimit
  }
}
`;
