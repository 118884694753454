import { defineStore } from "pinia";
import { ref } from "vue";
import { snakeToCamel } from "caseparser";

export const usePermissionsStore = defineStore("permissions", () => {
  const permObj = ref({
    pii: { read: 0, modify: 0, download: 0 },
    integ: { read: 0, modify: 0, download: 0 },
    perms: { read: 0, modify: 0, download: 0 },
    lyltProg: { read: 0, modify: 0, download: 0 },
    mktgAnal: { read: 0, modify: 0, download: 0 },
    dbAnalytics: { read: 0, modify: 0, download: 0 },
    createMktgCamp: { read: 0, modify: 0, download: 0 },
    store: { read: 0, modify: 0, download: 0 },
    billing: { read: 0, modify: 0, download: 0 },
    directMsg: { read: 0, modify: 0, download: 0 },
    creditHistory: { read: 0, modify: 0, download: 0 },
    admin: 0,
    owner: 0,
  });

  const setData = (permObject) => {
    for (const perm in permObject) {
      let camelCasePerm = snakeToCamel(perm);
      if (perm === "admin" || perm === "owner") {
        permObj.value[camelCasePerm] = parseInt(permObject[perm]);
      } else {
        let permValues = permObject[perm].split("|");
        permValues.forEach((permVal) => {
          if (permVal === "read") {
            permObj.value[camelCasePerm]["read"] = 1;
          } else if (permVal === "modify") {
            permObj.value[camelCasePerm]["modify"] = 1;
          } else if (permVal === "download") {
            permObj.value[camelCasePerm]["download"] = 1;
          }
        });
      }
    }
  };

  const hasPermission = (permName, permType) => {
    return (
      permObj.admin === 1 ||
      permObj.owner === 1 ||
      permObj.value[permName][permType] === 1
    );
  };

  const getPermCategory = (permName) => {
    if (permObj.value.owner === 1 || permObj.value.admin === 1) {
      return 3;
    } else {
      if (
        permObj.value[permName]["read"] === 1 &&
        permObj.value[permName]["modify"] === 0 &&
        permObj.value[permName]["download"] === 0
      ) {
        return 1;
      } else if (
        permObj.value[permName]["read"] === 1 &&
        permObj.value[permName]["modify"] === 1 &&
        permObj.value[permName]["download"] === 0
      ) {
        return 2;
      } else if (
        permObj.value[permName]["read"] === 1 &&
        permObj.value[permName]["modify"] === 1 &&
        permObj.value[permName]["download"] === 1
      ) {
        return 3;
      } else if (
        permObj.value[permName]["read"] === 1 &&
        permObj.value[permName]["modify"] === 0 &&
        permObj.value[permName]["download"] === 1
      ) {
        return 5;
      } else {
        return 4;
      }
    }
  };

  const message = "Sorry, you do not have the necessary permissions to do this";

  function showPermissionMsg(permissionCategory) {
    if (![2, 3].includes(permissionCategory)) {
      alert(message);
      return true;
    }
    return false;
  }

  return {
    permObj,
    setData,
    hasPermission,
    getPermCategory,
    showPermissionMsg,
    message,
  };
});
